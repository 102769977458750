import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Proveedor extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        autoIncrement: true,
        primaryKey: true,
        type: DataTypes['INTEGER']
      },
      nombre: {
				allowNull: false,
        type: DataTypes['STRING'](200)
      },
      rfc: {
				allowNull: true,
        type: DataTypes['STRING'](20)
      },
      email: {
				allowNull: true,
        type: DataTypes['STRING'](60)
      },
      telefono: {
				allowNull: false,
        type: DataTypes['STRING'](15)
      },
      activo: {
				allowNull: false,
        type: DataTypes['BOOLEAN'],
        defaultValue: true
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE'],
      },
      updated_at: {
        allowNull: true,
        type: DataTypes['DATE'],
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'proveedores',
			modelName: 'Proveedor',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.InsumoProveedor, as:'p_ip', include:[{model: this.sequelize.models.Insumo, as: 'ip_insumo'}]}
		];
	}
}
