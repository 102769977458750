const Path = require('path');
var fs=require('fs');
import MD5 from "crypto-js/md5";

export default { 
	///*
	textoConSaltos(texto, limite) {
		let lineas = [];
		let numLineas = 0;
		let count = 0;
		for(let i = 0; i < texto.length; i++) {
			if(count == limite) {
				numLineas++;
				count = 0;
			}
			if(!lineas[numLineas]) {
				lineas[numLineas] = '';
			}
			lineas[numLineas] += texto[i];
			count++;
		}
		return lineas;
	}, 
	///*/
	
	/*
	textoConSaltos(texto, limite) {
    if(limite <= 0) {
      return [];
    }
		let lineas = [];
		let numLineas = 0;
		let sinCortes = texto.split(" ");
        let temp = ''; 
        let temp2 = ''; 
        //console.log(sinCortes)
        for (let i=0; i < sinCortes.length; i++) {
          temp = temp + sinCortes[i]+ ' ';
          if(temp.length >= limite) { 
            console.log(temp.length + " >= " + limite);
            console.log(temp);
            lineas[numLineas] = temp2;
            numLineas++;
            temp2='';
            temp='';
          }
          temp2 = temp2 + sinCortes[i]+ ' ';
        }
		return lineas;
	}, 
	//*/
	
	leerJsonExterno(source) {
		const fullPath =  Path.resolve(__dirname, source);
		console.log('leyendo Archivo',fullPath);
		var data = fs.readFileSync(fullPath, 'utf8');
		return JSON.parse(data);
	},
	
	escribirLog(texto,path) {
		var logStream = fs.createWriteStream(path, {flags: 'a'});
		logStream.write(texto + "\n");
	},
	
	ajusteDecimal(value) {
    let cifras = (value + '').split('.');
    let decimales = '0';
    let enteros = Number(cifras[0]);
    if(cifras.length > 1) {
			decimales = Number('0.'+cifras[1]);
		}
    if(decimales <= 0.29) {
			decimales = 0;
		} else if(decimales > 0.29 && decimales < 0.5) {
			decimales = 0.5;
		} else if(decimales < 0.7) {
			decimales = 0.5;
		} else  if(decimales >= 0.7) {
			decimales = 0;
			enteros++;
		}
		return enteros + decimales;
  },
	
	fecha(d) {
		if(!d) {
			d = new Date();
		}
		let mes = (d.getMonth() + 1);
		let dia = d.getDate();
		return d.getFullYear() + '-' + (mes<10?'0':'') + mes + '-' + (dia<10?'0':'') + dia;
	},
	
	hora(d) {
		if(!d) {
			d = new Date();
		}
		let min = d.getMinutes();
		let seg = d.getSeconds();
		let h = d.getHours();
		return (h<10?'0':'') + h + ':' + (min<10?'0':'') + min + ':' + (seg<10?'0':'') + seg;
	},
	
	fechaHora() {
		return this.fecha() + ' ' + this.hora();
	},
	
	fechaToString(d) {
		if((typeof d).toUpperCase() == 'STRING') {
			return d;
		}
		return this.fecha(d) + ' ' + this.hora(d);
	},
	
	stringToDate(stringDate) { 
		if (!stringDate) {
      return new Date();
    }
		let anho = 0, mes = 0, dia = 0, h = 0, min = 0, seg = 0;
		let index = stringDate.search(/\d\d:\d\d:\d\d/);
		let hour = index >= 0? stringDate.substring(index,(index + 8)):null;
		index = stringDate.search(/\d\d\d\d-\d\d-\d\d/);
		let date = index >= 0? stringDate.substring(index,(index+10)):null;
		if(date) {
			anho = Number(date.split("-")[0]);
			mes = Number(date.split("-")[1]) - 1;
			dia = Number(date.split("-")[2]);
		}
		if(hour) {
			h = Number(hour.split(":")[0]);
			min = Number(hour.split(":")[1]);
			seg = Number(hour.split(":")[2]);
		}
		//return new Date().toLocaleString("es-MX", {timeZone: 'America/Mexico_City'})
		return new Date(anho,mes,dia,h,min,seg); // date no hacepta el formato en string YYY-MM-DD en el constructor
	},
	
	fechaToMinutos(d) {
		if((typeof d).toUpperCase() == 'STRING') {
			d = this.stringToDate(d);
		}
		let diasPorMes = {
			0:0, 1:31, 2:28, 3:31, 4:30, 5:31, 6:30, 7:31,  8:31, 9:30,
			10:31, 11:30, 12:31,
		};
		return (d.getFullYear() * 365 * 24 * 60) 
		+ (diasPorMes[d.getMonth()+1] * 24 * 60) 
		+ (d.getDate() * 24 * 60) + (d.getHours() * 60) + d.getMinutes();
	},
	
	minutosTranscurridos(fechaInicio) { // devuelve los minutos transcurridos desde una fecha hasta el momento actual
		let fAux = fechaInicio;
		if((typeof fechaInicio).toUpperCase() == 'STRING') {
			fAux = this.stringToDate(fAux);
		}
		return this.fechaToMinutos(new Date()) - this.fechaToMinutos(fAux);
	},
	
	restarFechas(f1,f2) { //regresa el restante en minutos
		return this.fechaToMinutos(f1) - this.fechaToMinutos(f2);
	},
	
	fechaDeHoy(d) { //valida si es de hoy la fecha string
		if((typeof d).toUpperCase() == 'STRING') {
			d = this.stringToDate(d);
		}
		let hoy = new Date();
		/*console.log("dia",d.getDate() +'=='+ hoy.getDate());
		console.log("mes",d.getMonth() +'=='+ hoy.getMonth());
		console.log("ànño",d.getFullYear() +'=='+ hoy.getFullYear());*/
		if(d.getFullYear() == hoy.getFullYear() && d.getMonth() == hoy.getMonth() && d.getDate() == hoy.getDate() ) {
			return true;
		}
		return false;
	},
	
	restarHoras(fecha,horas) {
		if((typeof fecha).toUpperCase() == 'STRING') {
			fecha = this.stringToDate(fecha);
		}
		let diasPorMes = {
			0:0, 1:31, 2:28, 3:31, 4:30, 5:31, 6:30, 7:31,  8:31, 9:30,
			10:31, 11:30, 12:31,
		};
		let min = fecha.getMinutes();
		let seg = fecha.getSeconds();
		let h = fecha.getHours();
		let d = fecha.getDate();
		let m = fecha.getMonth() + 1;
		let a = fecha.getFullYear();
		h = h - horas;
		if(h < 0) {
			d = d - 1;
			h = 24 + h; // se suma h por que es negativo y es equivalente a una resta;
			if(d == 0) {
				m = m - 1
				if(m == 0) {
					m = 12;
					a = a -1;
				}
				d = diasPorMes[m];
			}
		}
		return this.fechaToString(new Date(a,(m - 1),d,h,min,seg));
	},

	sumarHoras(fecha,horas) {
		if((typeof fecha).toUpperCase() == 'STRING') {
			fecha = this.stringToDate(fecha);
		}
		let diasPorMes = {
			0:0, 1:31, 2:28, 3:31, 4:30, 5:31, 6:30, 7:31,  8:31, 9:30,
			10:31, 11:30, 12:31,
		};
		let min = fecha.getMinutes();
		let seg = fecha.getSeconds();
		let h = fecha.getHours();
		let d = fecha.getDate();
		let m = fecha.getMonth() + 1;
		let a = fecha.getFullYear();
		h = h + horas;
		if(h > 23) {
			d = d + 1;
			h = h - 24; 
			if(d > diasPorMes[m]) {
				m = m + 1;
				d = 1;
				if(m > 12) {
					m = 1;
					a = a + 1;
				}
				
			}
		}
		return this.fechaToString(new Date(a,(m - 1),d,h,min,seg));
	},
	
	hash(text) {
		return MD5(text).toString()
	},
};
