<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>MODIFICAR PRECIO POR CATEGORIA </v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-select solo label="Categoria" v-model="categoria" :items="categorias"  :rules="reglas.categoria" 
						></v-select>
						<v-select solo label="Accion" v-model="accion" :items="unidades" @change="(accion == 'Decrementar')? veradvertencia=true:veradvertencia =false " :rules="reglas.unidad_medida" 
						></v-select>
						<v-alert
							color="red"
							dismissible
							v-if="veradvertencia"
							elevation="3"
							icon="fa-exclamation-circle"
							type="warning"
							> Al decrementar, el monto minimo es cero por lo que si la cantidad que ingresa es superior al precio sera ajustada automaticamente a cero.</v-alert>
						<v-text-field solo label="Cantidad" v-model="cantidad" :rules="reglas.nombre"></v-text-field>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			
		},
    data () { return { 
		    categoria:null,
			accion:null,
			cantidad:null,
			veradvertencia:false,
			unidades:['Incrementar', 'Decrementar'],
			categorias:['RES', 'CERDO', 'LACTEOS', 'EMBUTIDOS','OTROS','PROMOCIONES','POLLO','PEZ','BIMBO','VERDURAS','ABARROTES'],
			reglas: {
				nombre:[
					(val) => !!val || "Ingresa la cantidad" ,
				],
				categoria:[
					(val) => !!val || "Selecciona la categoria" ,
				],
				unidad_medida:[
					(val) => !!val || "Selecciona Una" ,
				],
			}
		};},
		mounted() {},
    methods: {
			guardar() {
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				// enviar tres parametros 
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Producto.buscarPor('categoria',this.categoria, (response) => {
					//console.log("productos arr ->",response);
					let arreglosAactualizar = [];
					for (let index = 0; index < response.data.length; index++) {
						//console.log("productos -> ",response.data[index].p_precios);
						let preciosArray = response.data[index].p_precios
						for (let index2 = 0; index2 < preciosArray.length; index2++) {
						//	console.log("precio -> ",response.data.p_precios[index]);
						    let element = preciosArray[index2];
							if( element){ 
								//console.log("si trae")
								if(this.accion == 'Decrementar'){
									if((Number(element.monto) + Number(this.cantidad)) <= 0){
										element.monto = 0 ;
									}else{
										element.monto = Number(element.monto) + Number(this.cantidad);
									}
								}else{
									element.monto = Number(element.monto) + Number(this.cantidad);
								}
								let precio = element;
								//console.log(precio)
								arreglosAactualizar.push(precio)
								//Schema.models.Precio.guardar( precio, 'own', resposne=>{ } );
							}else{
								console.log("no trae")
							}
							//console.log("monto ", preciosArray[index].id)
						}
					}

					console.log(arreglosAactualizar)
					Schema.models.Precio.guardarTodos(arreglosAactualizar,'own', response=>{
						console.log(response)
						this.$store.commit('setsplash',{texto:'Guardando', activar: false});
						this.categoria = null
			            this.accion = null
			            this.cantidad = null
						this.$emit('getAll',null);
					} )
					
				});
			},
			cerrar() {
				this.$emit('getproducto',null);
			},
			generarClave(id) {
				let folio = '' + (!!this.producto.id?this.producto.id:id);
				if(folio.length < 6) {
					let cerosIzquierda = 6 - folio.length;
					for(let i = 0; i < cerosIzquierda; i++) {
						folio = '0' + folio;
					}
				}
				this.producto.clave = this.producto.categoria.substring(0,3) + folio;
			}
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
