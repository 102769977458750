import { DataTypes, Op } from '../utils/DataTypes';
import Helper from './Helper';

export default class AppModel {
  constructor(sequelize, origin,crud) {
    this.sequelize = sequelize;
    this.socketIO = null;
    this.socketCli = null;
    this.store = null;
    this.model = null;
    this.fieldsValids = [];
    this.primaryKey = 'id';
    this.atributos = null;
    this.options = null;
    this.origin = origin;
    this.nodo = null;
    this.crud = crud;
    this.handlers = {};
    this.mutationStore = 'setprocessdata';
  }
  
  count(handler) {
		this.attachSelectJob({},'_count',handler);
	}
  
  creadosDesdeFecha(fecha , handler) {
		this.attachSelectJob({fecha},'_createdSince',handler);
	}
  
  creadosEnFecha(fecha,handler) {
		this.attachSelectJob({fecha},'_createdIn',handler);
	}
  
  creadosEntreFechas(inicio,fin,handler) {
		this.attachSelectJob({ inicio, fin },'_createdBetween',handler);
	}
  
  datosDesdeFecha(fecha, handler) {
		this.attachSelectJob({fecha},'_dataSince',handler);
	}
  
  get(id, handler) {
		this.attachSelectJob(id,'_find',handler);
	}
  
  buscarSimilar(campo, valor, handler) {
		this.attachSelectJob({campo, valor, limit:20},'_findLike',handler);
	}
  
  buscarPor(campo, valor, handler) {
		this.attachSelectJob({campo, valor},'_findBy',handler);
	}
  
  listar(limit, page, handler) {
		this.attachSelectJob({limit,page},'_paginate',handler);
	}
  
  borrar(data, broadcast,handler) {
		this.attachExecuteJob(data,'_delete',broadcast,handler);
	}
  
  guardar(data, broadcast,handler) {
		this.attachExecuteJob(data,'_save',broadcast,handler);
	}
  
  guardarTodos(data, broadcast,handler) {
		this.attachExecuteJob(data,'_saveAll',broadcast,handler);
	}
	
	attachSelectJob(data, functionName, handler) {
		if((typeof functionName).toUpperCase() !== 'STRING') {
			console.error('Error',functionName + ' no es valido como parametro functionName');
			return;
		}
		if((typeof handler).toUpperCase() !== 'FUNCTION') {
			console.error('Error',handler + ' no es valido parametro campo handler');
			return;
		}
		let job = this.createJob(data,functionName,'own');
		this.setHandler(handler,job);
		this.processJob(job).then((theJob) => {
			if(!this.crud) {
				this.sendJobToServer(theJob);
			}
		});
	}
	
	attachExecuteJob(data, functionName, broadcast, handler) {
		if((typeof broadcast).toUpperCase() !== 'STRING' || !['all', 'own', 'destiny', 'none'].includes(broadcast)) {
			console.error('Error',broadcast + ' no es valido como parametro broadcast');
			return;
		}
		if((typeof functionName).toUpperCase() !== 'STRING') {
			console.error('Error',functionName + ' no es valido como parametro functionName');
			return;
		}
		if((typeof handler).toUpperCase() !== 'FUNCTION') {
			console.error('Error',handler + ' no es valido parametro campo handler');
			return;
		}
		let job = this.createJob(data,functionName,broadcast);
		this.setHandler(handler,job);
		this.processJob(job).then((theJob) => {
			 this.sendJobToServer(theJob);
		});
	}
	async validaciones(data) {
		return {error: false, message:''};
	}
  
  async processJob(job) {
		if(!job['newData']) {
			job['newData'] = null;
		}
		if(this.crud) {
			let validate = await this.validaciones(job);
			if(validate.error) {
				job['error'] = validate.message;
				this.handlerResult(job);
				return job;
			}
			console.log("procesando evento, lado " + this.origin, job.action);
			try {
				job['newData'] = await this[job.action](job.data);
				if(this.origin === 'client' && job.model != 'Sesion') {
					job.data = job.newData;
				}
			} catch(e) {
				job['error'] = e;
				console.log(job.action + "-" + job.model,e);
			}
			this.handlerResult(job);
		}
		return job;
	}
	
	async _paginate(data) {
		let options = {
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		};
		if(!!data.limit) {
			options['limit'] = data.limit;
			if(!!data.page) {
				options['offset'] = ((data.page - 1) * data.limit);
			}
		}
		return await this.model.findAll(options);
	}
	
  async _save(data) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} 
		if(model) {
			if(this.fieldsValids.includes('updated_at')) {
				data['updated_at']= Helper.fechaHora();
			}
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					model[field] = data[field];
				}
			}
			await model.save();
		} else {
			if(this.fieldsValids.includes('created_at')) {
				data['created_at']= Helper.fechaHora();
			}
			if(this.fieldsValids.includes('updated_at')) {
				data['updated_at']= Helper.fechaHora();
			}
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		if(this.fieldsValids.includes('created_at')) {
			model.created_at= Helper.fechaToString(model.created_at);
		}
		if(this.fieldsValids.includes('updated_at')) {
			model.updated_at = Helper.fechaToString(model.updated_at);
		}
		return model;
	}
	
	async _saveAll(arrayData) {
		let newData = [];
		for(let i = 0; i < arrayData.length; i++) {
			newData.push(await this._save(arrayData[i]));
		}
		return newData;
	}
	
  async _delete(id) {
		let model = null;
		model = await this.model.findByPk(id);
		await model.destroy();
		return id;
	}
	
  async _find(id) {
		return await this.model.findByPk(id,{
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
		});
	}
	
  async _findBy(data) {
		return await this.model.findAll({
			where: {[data.campo]:data.valor},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
			//limit:data.limit
		});
	}
	
  async _findLike(data) {
		return await this.model.findAll({
			where: {[data.campo]:{[Op.like]:'%' + data.valor + '%'}},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
			limit:data.limit
		});
	}
	
	async _dataSince(data) {
		let where = {};
		if(this.fieldsValids.includes('created_at') && this.fieldsValids.includes('updated_at')) {
			where[Op.or] = {'created_at': {[Op.gte]:data.fecha}, 'updated_at': {[Op.gte]:data.fecha}};
		} else if(this.fieldsValids.includes('created_at')) {
			where['created_at'] =  {[Op.gte]:data.fecha};
		} else if(this.fieldsValids.includes('updated_at')) {
			where['updated_at'] = {[Op.gte]:data.fecha};
		} else {
			return [];
		}
		return await this.model.findAll({
			where,
			attributes: this.fieldsValids,
			//include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	async _createdSince(data) {
		if(!this.fieldsValids.includes('created_at')) {
			return [];
		}
		return await this.model.findAll({
			where: {created_at: {[Op.gte]:data.fecha}},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	async _createdIn(data) {
		if(!this.fieldsValids.includes('created_at')) {
			return [];
		}
		return await this.model.findAll({
			where: {created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']}},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	async _createdBetween(data) {
		if(!this.fieldsValids.includes('created_at')) {
			return [];
		}
		return await this.model.findAll({
			where: {created_at:{[Op.between]: [data.inicio + ' 00:00:00', data.fin + ' 23:59:59']}},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
  async _count() {
		return await this.model.count();
	}
  
	/****client whit crud side*****/
	async enviarDatosAlServer(fecha) {
		let data = await this._dataSince({fecha});
		this.sendJobToServer(this.createJob(data,'_saveAll','own'));
	}
	
	/****client side*****/
	
	createJob(data, action, broadcast) {
		return {
			data,
			action,
			socket_id:this.socketCli?this.socketCli.id:null,
			broadcast,
			model:this.options.modelName
		};
	}
	
	setHandler(handler,job) {
		if(typeof handler === 'function') { 
			this.handlers[job.action +'-'+job.model] = handler;
		} else {
			console.log("no se puede crear handler ",job.action +'-'+job.model);
			console.log((typeof handler),handler);
		}
	}
	
	handlerResult(job) {
		if(this.origin === 'server') {
			return;
		}
		if(!!this.handlers[job.action +'-'+job.model] && typeof this.handlers[job.action +'-'+job.model] === 'function' 
		&&  this.isMyJob(job)) { 
			this.handlers[job.action +'-'+job.model](job);
			this.handlers[job.action +'-'+job.model] = null;
		}
	}
	
	async tryProcessJobAndHandler(job) {
		if(job.model != 'Sesion' && !!job.newData) {
			job.data = job.newData;
		}
		if(this.crud && this.nodo != 'principal') {
			return;
		}
		console.log("recibiendo en cliente",job);
		job = await this.processJob(job);
		if(this.store) {
			this.store.commit(this.mutationStore,job);
		}
		this.handlerResult(job);
		return job;
	}
	
	sendJobToServer(job) {
		if(this.socketCli && this.socketCli.connected) {
			this.socketCli.emit('crud_channel_server',job);
		}
	}
	
	isMyJob(job) {
		if(!!this.socketCli) {
			return this.socketCli.id == job.socket_id;
		}
		return true;
	}
	
	/****server side*****/
	
	async enviarDatosAlCliente(socket,fecha) {
		let data = await this._dataSince({fecha});
		console.log("enviar al cliente desde " + fecha + ' ' + this.options.modelName, data.length);
		socket.emit('crud_channel_client',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:socket.id,
			broadcast:'own',
			model:this.options.modelName,
		});
	}
	
	async processAndBroadcast(job) {
		console.log("recibiendo en server",job);
		this.broadcast(await this.processJob(job));
		return true;
	}
	
	broadcast(job) {
		Helper.escribirLog("Broadcast: " + JSON.stringify(job),'log.txt');
		if(job.broadcast == 'all') {
			this.socketIO.emit('crud_channel_client',job);
		}
		if(job.broadcast == 'own') {
			this.socketCli.emit('crud_channel_client',job);
		}
		if(job.broadcast == 'destiny') {
			this.socketIO.to(job.destiny).emit('crud_channel_client',job);
		}
	}
	/*******************/

  setSocket(ioSocket, socketCli) {
    this.socketIO = ioSocket;
    this.socketCli = socketCli;
  }

  setStore(store,mutationName) {
    this.store = store;
    this.mutationStore = mutationName;
  }

  setNodo(nodo) {
    this.nodo = nodo;
  }

  getModel() {
    return this.model;
  }

  getConnection() {
    return this.sequelize;
  }
}
