<template>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Compras a proveedores </v-card-title>
		</v-toolbar>
		<v-card-text>
			<h3>Infomacion de la compra</h3>
			<v-divider></v-divider>
			<v-row class="no-mp">
				<v-col cols="3">
					<v-row class="no-mp">
						<v-text-field label="Folio de la Factura" outlined v-model="compra.factura" ></v-text-field>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
							offset-y min-width="auto" >
							<template v-slot:activator="{ on, attrs }">
								<v-text-field  label="Fecha de la compra" outlined v-model="compra.fecha"  readonly v-bind="attrs" v-on="on"
								></v-text-field>
							</template>
							<v-date-picker  @input="menu2 = false" v-model="compra.fecha"></v-date-picker>
						</v-menu>
						<v-text-field label="Descuento" outlined v-model="compra.descuento" ></v-text-field>
						<v-text-field label="Impuesto" outlined v-model="compra.iep" ></v-text-field>
						<v-select
							:items="items"
							v-model="compra.area_id"
							label="Area"
							item-text="nombre"
          					item-value="id"
							dense
							outlined
						></v-select>
						<v-card style="padding:20px;font-size:17px; text-align:center">
							<div >Total Parcial </div>
							<div>$ {{totalParcial | moneda}}</div>
							<div >Total de la Compra </div>
							<div>$ {{totalCompra | moneda}}</div>
							<div><v-btn color="success" block v-on:click="confirmarCompra">Guardar Compra</v-btn></div>
						</v-card>
						<v-card style="padding:20px; margin:10px 0 10px 0" v-if="proveedorSelect">
							<h4>Proveedor Seleccionado: </h4>
							<div>{{proveedorSelect.nombre}}</div>
							<div>{{proveedorSelect.rfc}}</div>
							<div>{{proveedorSelect.telefono}}</div>
							<div>{{proveedorSelect.email}}</div>
						</v-card>
					</v-row>
				</v-col>
				<v-col cols="9">
					<v-row class="no-mp">
						<v-autocomplete  :items="proveedores"  item-text="nombre" label="Proveedor" v-model="proveedorSelect" 
						return-object></v-autocomplete>
					</v-row>
					<v-row class="no-mp" v-if="!!proveedorSelect">
						<EntradasFormList  :proveedor="proveedorSelect" :insumos_proveedor="proveedorSelect.p_ip" v-on:getentradas="getEntradas"/>
					</v-row>
				</v-col>
			</v-row>
			
			<v-divider></v-divider>
		</v-card-text>
		<v-dialog v-model="modalConfirm" width="600">
			<v-card>
				<v-toolbar color="#D3D5D8"  dense> 
					<v-spacer></v-spacer>
					<v-card-title>Confirmar Compra</v-card-title>
				</v-toolbar>
				<v-row class="no-mp">
					<v-col class="column">Producto</v-col>
					<v-col class="column">Entrada</v-col>
					<v-col class="column">Precio compra</v-col>
					<v-col class="column">Total</v-col>
				</v-row>
				<v-row class="no-mp" v-for="e,index in entradasReales" :key="index + 'enta'">
					<v-col class="column">{{e.insumo.nombre}}</v-col>
					<v-col class="column">{{e.cantidad}}</v-col>
					<v-col class="column">$ {{e.precio}}</v-col>
					<v-col class="column">$ {{totalEntrada(e)}}</v-col>
				</v-row>
				<v-card-actions>
					<v-btn small color="success" dark v-on:click="guardarCompra">Aceptar</v-btn>
					<v-btn small color="red" dark v-on:click="modalConfirm = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import Schema from '../core/Schema';
	import EntradasFormList from '../reutilizables/entradas/EntradasFormList';
  export default {
    components:{ EntradasFormList},
    props: { },
    data () { return { 
			menu2:false,
			modalConfirm:false,
			items: [],
			compra: {
				fecha:null,
				area_id:null,
				total:0,
				pagado:0,
				descuento:null,
				iep:null,
				factura:null,
				proveedor_id:null,
			},
			proveedores:[],
			proveedorSelect:null,
			entradas:[],
		};},
		mounted() { 
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Proveedor.listar(null,null,job => {
				this.proveedores = job.newData;
				//console.log('provedores', job.newData)
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
			Schema.models.Area.listar(null,null,job => {
				this.items = job.newData;
				//console.log('Areas', job.newData)
				//this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
    methods: {
			totalEntrada(i) {
				return Number(i.cantidad) * Number(i.precio);
			},
			confirmarCompra() {
				this.modalConfirm = true;
			},
			getEntradas(e) {
				this.entradas = e;
			},
			guardarCompra() {
				if(!this.compra.fecha) {
					alert("Agrega la fecha de la compra");
					return;
				}
				if(this.compra.total <= 0) {
					alert("No se agrego ninguna entrada");
					return;
				}
				this.compra.proveedor_id = this.proveedorSelect.id;
				this.$store.commit('setsplash',{texto:'Guardando compra', activar: true});
				console.log('va folio ?', this.compra)
				Schema.models.Compra.guardarCompra(this.compra,this.entradas,job => {
					this.proveedorSelect = null;
					Schema.models.Proveedor.listar(null,null,job => { //cargamos de nuevo los proveedores y los insumos
						this.compra = {
							fecha:null,
							total:0,
							pagado:0,
							factura:null,
						};
						this.entradas = [];
						alert("Compra guardada");
						this.proveedores = job.newData;
						this.modalConfirm = false;
						this.$store.commit('setsplash',{texto:'', activar: false});
					});
				});
			},
		},
		computed: {
			totalCompra() {
				return this.totalParcial - Number(this.compra.descuento) + Number(this.compra.iep);
			},
			totalParcial() {
				this.compra.total = 0;
				this.entradas.forEach((item) => {
					this.compra.total += Number(item.precio) * Number(item.cantidad);
				});
				
				return this.compra.total;
			},
			entradasReales() {
				let c = [];
				this.entradas.forEach((item) => {
					if(Number(item.cantidad) > 0) {
						c.push(item);
					}
				});
				return c;
			}
		},
		watch: {}
  }
</script>
<style>
</style>
