<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{proveedor.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-text-field solo label="Nombre" v-model="proveedor.nombre" :rules="reglas.nombre"></v-text-field>
						<v-text-field solo label="RFC" v-model="proveedor.rfc" :rules="reglas.rfc"></v-text-field>
						<v-text-field solo label="Telefono" v-model="proveedor.telefono" :rules="reglas.telefono"></v-text-field>
						<v-text-field solo label="Email" v-model="proveedor.email" :rules="reglas.email"></v-text-field>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			proveedor: {
				type:Object,
				default() { return { 
					nombre:null,
					rfc:null,
					telefono:null,
					email:null,
					activo:1,
				};}
			}
		},
    data () { return { 
			reglas: {
				nombre:[
					(val) => !!val || 'Agrega nombre'
				],
				rfc:[
					(val) => !!val || 'Agrega RFC'
				],
				telefono:[
					(val) => !!val || 'Agrega Telefono'
				],
				email:[
					(val) => !!val || 'Agrega Email'
				],
			}
		};},
		mounted() {},
    methods: {
			guardar() {
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Proveedor.guardar(this.proveedor,'all',(job) => {
					this.$emit('getproveedor',job.newData);
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
				
			},
			cerrar() {
				this.$emit('getproveedor',null);
			},
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
