import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import municipios from '../utils/municipios';

export default class Area extends AppModel {
  constructor(sequelize, socket, origin,crud) {
    super(sequelize, socket, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			nombre: {
				allowNull: false,
				type: DataTypes['STRING'](30),
			},
            created_at: {
                allowNull: true,
				type: DataTypes['DATE'],
            },
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
            tableName: 'areas',
            modelName: 'Area',
            timestamps: false,
            freezeTableName: true,
            underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			
		];
	}
	
}
