import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class TipoCliente extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['INTEGER'],
				//defaultValue: NULL
			},
			nombre: {
				allowNull: false,
				type: DataTypes['STRING'](50),
			},
			
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'tipo_clientes',
			modelName: 'TipoCliente',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			//{model:this.sequelize.models.Cliente, as:'t_clientes'},
			//{model:this.sequelize.models.Precio, as:'t_precios'},
		];
	}
  
}
