import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class PagoPedido extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = { 
			id: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING'],
			},
			pedido_id: {
				allowNull: false,
				type: DataTypes['STRING'],
				references: {
					model: 'Pedido',
					key: 'id'
				}
			},
			total: {
				allowNull: false,
				type: DataTypes['FLOAT'],
				defaultValue: 0
			},
			tipo: {
				allowNull: false,
				type: DataTypes['ENUM']('efectivo','transferencia','cheque','tarjeta'),
				defaultValue: 'efectivo'
			},
			cobrador_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			created_at: {
				allowNull: true,
				type: DataTypes.DATE,
			},
    };
    this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'pagos_pedidos',
			modelName: 'PagoPedido',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{ model: this.sequelize.models.User,  as:'cobrador' },
			{ 
				model: this.sequelize.models.Pedido,  
				as:'p_pedido',
				attributes: Schema.models.Pedido.fieldsValids,
				include:[{
					model: this.sequelize.models.Cliente,
					attributes: ['id',
					'asentamiento_id',
					'tipo_cliente_id',
					'razon_social',
					'encargado',
					'es_empresa',
					'telefono',
					'movil',
					'calles',
					'andador',
					'manzana',
					'seccion',
					'numero_exterior',
					'numero_interior',
					'notas',
					'created_at',
					'updated_at',
					'id_user',], 
					as:'cliente'
				}],
			},
		];
	}
	
	pagosDiaByUser(dia, handler) {
		this.attachSelectJob({dia},'_pagosDiaByUser',handler);
	}
	
	async _pagosDiaByUser(data) {
		if(!this.fieldsValids.includes('created_at')) {
			return [];
		}
		let pagosUser = {};
		let pagos = await this.model.findAll({
			where: {
				created_at:{[Op.between]: [data.dia + ' 00:00:00', data.dia + ' 23:59:59']},
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
		pagos.forEach(p => {
			if(!pagosUser[p.cobrador_id]) {
				pagosUser[p.cobrador_id] = {
					user: p.cobrador,
					pagos:[],
					efectivo:0,
					transferencia:0,
					cheque:0,
				};
			}
			if(!p.p_pedido.cancelado) {
				pagosUser[p.cobrador_id].pagos.push(p);
				if(p.tipo == 'efectivo') {
					pagosUser[p.cobrador_id].efectivo += Number(p.total);
				}
				if(p.tipo == 'transferencia') {
					pagosUser[p.cobrador_id].transferencia += Number(p.total);
				}
				if(p.tipo == 'cheque') {
					pagosUser[p.cobrador_id].cheque += Number(p.total);
				}
			}
		});
		return pagosUser;
	}
	
	pagosDiaUser(dia,user_id, handler) {
		this.attachSelectJob({dia,user_id},'_pagosDiaUser',handler);
	}
	
	async _pagosDiaUser(data) {
		if(!this.fieldsValids.includes('created_at')) {
			return [];
		}
		let pagos = await this.model.findAll({
			where: {
				created_at:{[Op.between]: [data.dia + ' 00:00:00', data.dia + ' 23:59:59']},
				cobrador_id:data.user_id,
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
		let efectivo = 0;
		let transferencia = 0;
		let cheque = 0;
		pagos.forEach(p => {
			if(!p.p_pedido.cancelado) {
				if(p.tipo == 'efectivo') {
					efectivo += Number(p.total);
				}
				if(p.tipo == 'transferencia') {
					transferencia += Number(p.total);
				}
				if(p.tipo == 'cheque') {
					cheque += Number(p.total);
				}
				
			}
		});
		let info = {dia:data.dia,user_id:data.user_id,pagos,efectivo,transferencia,cheque};
		return {dia:data.dia,user_id:data.user_id,pagos,efectivo,transferencia,cheque};
	}
	
	/**
   * @override
  **/
  async _saveAll(arrayData, sucursal_id) {
		let newData = [];
		for(let i = 0; i < arrayData.length; i++) {
			newData.push(await this._save(arrayData[i], sucursal_id));
		}
		return newData;
	}
	
	/**
   * @override
  **/
  async _save(data, sucursal_id) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			let idSig = await Schema.models.Id._getIdSiguiente('pagos',sucursal_id);
			//console.log("id,siguiente items", idSig);
			data.id = idSig  + ':' + sucursal_id;
			await Schema.models.Id._amuentarId('pagos', sucursal_id);
		}
		if(model) {
			this.fieldsValids.forEach((field) => {
				model[field] = !!data[field]?data[field]:model[field];
			});
			await model.save();
		} else {
			data['created_at']= Helper.fechaHora();
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		model.created_at= Helper.fechaToString(model.created_at);
		return model;
	}
}

