import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Movimiento extends AppModel {
    constructor(sequelize, origin, crud) {
        super(sequelize, origin, crud);
        this.atributos = {
            id: {
                allowNull: false,
                autoIncrement: true,
                primaryKey: true,
                type: DataTypes['INTEGER'],
            },
            accion: {
                allowNull: false,
                type: DataTypes['TEXT'],
            },
            tabla: {
                allowNull: false,
                type: DataTypes['TEXT'],
            },
            descripcion: {
                allowNull: false,
                type: DataTypes['TEXT'],
            },
            foreign_key: {
                allowNull: false,
                type: DataTypes['TEXT'],
            },
            user_id: {
                allowNull: false,
                type: DataTypes['INTEGER'],
            },
            pendiente: {
                allowNull: false,
                type: DataTypes['BOOLEAN'],
                default: true,
            },
            created_at: {
                allowNull: true,
                type: DataTypes['DATE'],
            },
        };
        this.fieldsValids = Object.keys(this.atributos);
        this.options = {
            tableName: 'movimientos',
            modelName: 'Movimiento',
            timestamps: false,
            freezeTableName: true,
            underscored: true,
            // Hooks: https://sequelize.org/master/manual/hooks.html
        };
        if (this.crud) {
            this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
        }
    }// constructor.

    includeAssociations() {
        return [
          //  { model: this.sequelize.models.Pedido, as: 'c_pedido' },
        ];
    }

    crearReseteo(reseteo, handler) {
        this.attachExecuteJob({ reseteo }, '_saveReseteo', 'own', handler);
    }

    async _saveReseteo(data) {
        //let reseteo = await this._save(data.reseteo);
        console.log("data tiene", data);
        //console.log("movimiento registrado y actualizado", data.reseteo);
        let pedido = await this.sequelize.models.Pedido.findByPk(data.reseteo.foreign_key, {
					attributes: [
						'id', 'sucursal_id', 'empleado_id', 'repartidor_id', 'tablajero_id', 'cliente_id', 'estado', 'created_at',
						'fecha_in_produccion', 'fecha_out_produccion', 'fecha_reparto', 'fecha_entregado', 'fecha_finalizado', 'total',
						'pagos', 'cancelado'
					],
					include:[ {model: this.sequelize.models.PagoPedido, as: 'abonos'} ],
				});
        if (pedido === null){
            return data;
        }
            console.log("Abonos", pedido.abonos);
            for ( let cont =0 ; cont < pedido.abonos.length ; cont++ ){
                let abono = await this.sequelize.models.PagoPedido.findByPk(pedido.abonos[cont].id);
                abono.destroy();
            }
            
            pedido.estado = 'cobro';
            pedido.pagos = 0;
            pedido.save();
            data['pedido'] = pedido;
            data.reseteo = await this._save(data.reseteo);
        
        console.log("movimiento registrado y actualizado", data.reseteo);
        return  data;
    }

    crearCancelacion(cancelacion, socketId, handler) {
        this.attachExecuteJob({ cancelacion, socketId }, '_saveCancelacion', 'own', handler);
    }

    async _saveAll(arrayData) {
        let newData = [];
        for (let i = 0; i < arrayData.length; i++) {
            let m = await this._saveReseteo({reseteo: arrayData[i]});
            newData.push(m);
        }
        return newData;
    }
}

