<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{producto.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" cols="12" xs="12" sm="12" md="6" lg="6">
				<v-form ref="form">
					<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;" v-if="precio">
						<h3>Nuevo precio</h3>
						<v-text-field solo label="Monto" v-model="precio.monto" :rules="reglas.monto" prefix="$"></v-text-field>
						<v-select solo label="Tipo de Precio" v-model="precio.tipo" :items="tipos_precios" :rules="reglas.tipo"></v-select>
						<div v-if="precio.tipo === 'general'">
							<v-select solo label="Selecciona el Tipo Cliente" v-model="precio.tipo_cliente_id" :items="tipos_cliente" 
							:rules="reglas.tipo_cliente_id" :item-value="'id'" :item-text="'nombre'"></v-select>
						</div>				
						<div v-if="precio.tipo === 'especial'">
							<ClienteTinySearch v-on:getcliente="getCliente"/>
						</div>				
						<div v-if="precio.tipo === 'condicional'">
							<v-card style="padding:0 0 0 10px;">
								<v-switch  v-model="conFechas" label="Fechas" ></v-switch>
								<v-row  style="margin:2px; padding:0;" v-if="conFechas">
									<v-col style="margin:0; padding:2px 5px 2px 5px;" cols="6">
										<v-menu :close-on-content-click="false" v-model="fecha1Pop">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="precio.fecha" label="Fecha" prepend-icon="fa fa-calendar"
												readonly v-bind="attrs" v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="precio.fecha"  @input="fecha1Pop = false" ></v-date-picker>
										</v-menu>
									</v-col>
									<!--
									<v-col style="margin:0; padding:2px 5px 2px 5px;" cols="6">
										<v-text-field readonly v-model="precio.fecha_fin" label="Fecha 2"></v-text-field>
									</v-col>
									-->
								</v-row>
								<v-switch  v-model="conCond" label="Volumen" ></v-switch>
								<v-row  style="margin:2px; padding:0;" v-if="conCond">
									<v-col style="padding:0 10px 0 0; margin:0 3px 0 1px;" cols="4">
										<v-select reverse v-model="precio.condicion" :items="condiciones"></v-select>
									</v-col>
									<v-col class="no-mp" cols="5">
										<v-text-field reverse type="number" v-model="precio.volumen" :prefix="producto.unidad_medida"></v-text-field>
									</v-col>
								</v-row>
							</v-card>
						</div>
						<br>				
						<v-btn block color="success" v-on:click="guardar" >Guardar</v-btn>
					</v-card>
				</v-form>
			</v-col>
			<v-col class="no-mp" cols="12" xs="12" sm="12" md="6" lg="6">
				<v-card elevation="0" outlined style="margin:10px; padding:10px; background-color:#F0F0F0;" v-if="datosCargados">
					<h3>Precios Guardados</h3>
					<v-card elevation="0" outlined tile style="margin:3px 0 3px 0; padding:3px"
					v-for="(p,index) in precios" :key="index">
						<v-row class="no-mp">
							<v-col class="no-mp" cols="1"></v-col>
							<v-col class="no-mp" cols="6">
								<div class="xsmall-text">Precio tipo {{p.tipo}}</div>
								<div class="xsmall-text">{{precioInfo(p)}}</div>
							</v-col>
							<v-col class="no-mp" cols="3"><v-chip x-small color="primary" dark>$ {{p.monto | moneda}}</v-chip></v-col>
							<v-col class="no-mp" cols="2">
								<v-btn x-small  elevation="0" color="warning" v-on:click="editar(p,index)"> <i class="fa fa-edit"></i></v-btn>
								<v-btn x-small  elevation="0" color="error" v-on:click="borrar(p,index)"> <i class="fa fa-trash"></i></v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
		<!-- -->
		<v-dialog v-model="precioModal" width="500px">
			<v-card v-if="!!precioSelect">
				<v-toolbar color="#D3D5D8"  dense> 
					<v-btn icon v-on:click="cerrarEditarPrecio()" > <i class="fa fa-times"></i></v-btn>
					<v-spacer></v-spacer>
					<v-card-title>Editando precio</v-card-title>
				</v-toolbar>
				<v-card-text style="margin:0; padding:20px">
					<v-row class="no-mp">
						<v-col class="no-mp" cols="3"><v-chip x-small label>ID: {{precioSelect.id}}</v-chip></v-col>
						<v-col class="no-mp" cols="6">
							<div class="xsmall-text">Precio tipo {{precioSelect.tipo}}</div>
							<div class="xsmall-text">{{precioInfo(precioSelect)}}</div>
						</v-col>
						<v-col class="no-mp" cols="3">
							<v-text-field solo label="Monto" v-model="precioSelect.monto"  prefix="$"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn x-small color="primary" v-on:click="editarPrecio()">Guardar</v-btn>
					<v-btn x-small color="error" v-on:click="cerrarEditarPrecio()">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- -->
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
	import ClienteTinySearch from '../clientes/ClienteTinySearch';
  export default {
    components:{
			ClienteTinySearch
		},
    props: {
			precios: {
				type:Array,
				default() {return [];}
			},
			producto: {
				type:Object,
				default() {return {
					nombre:'producto',
					unidad_medida:'Kg',
				};}
			}
		},
    data: () => ({
			fecha1Pop:false,
			newPrecio: {
				producto_id: null,
				monto:null,
				tipo:null, //general, especial, condicional
				tipo_cliente_id: null,
				cliente_id: null,
				clientep:null,
				// datos para el condicional
				fecha:null, // fecha en especifico
				fecha_fin:null,
				condicion:null,// mayor, igual, menor
				volumen:null,
			},
			reglas: {
				monto:[
					(val) => !!val || "Agrega un monto" ,
				],
				tipo:[
					(val) => !!val || "Selecciona un tipo de precio" ,
				],
				tipo_cliente_id: [
					(val) => !!val || "Selecciona el tipo de cliente" ,
				],
				cliente_id: [
					(val) => !!val || "Ingresa Nombre" ,
				],
				fecha:null, // fecha en especifico
				fecha_fin:null,
				condicion:null,// mayor, igual, menor
				volumen:null,
			},
			precio: null,
			tipos_precios: ['general','especial', 'condicional'],
			tipos_cliente: [],
			fechaModal: false,
			conFechas: false,
			conCond: false,
			condiciones: ['mayor','menor','igual'],
			precioSelect:null,
			index:null,
			precioModal:null,
		}),
		mounted() {
			this.precio = {...this.newPrecio};
			this.$store.commit('setsplash',{texto:'Cargando Precios', activar: true});
			Schema.models.TipoCliente.listar(null,null,(job) => {
				this.tipos_cliente = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
    methods: {
			editar(p,index) { 
				this.precioSelect = p;
				this.index = index;
				this.precioModal = true;
			},
			editarPrecio() {
				this.$store.commit('setsplash',{texto:'Guardando...', activar: true});
				Schema.models.Precio.guardar(this.precioSelect,'all',(job) => {
					this.cerrarEditarPrecio();
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			cerrarEditarPrecio() {
				this.precioSelect = null;
				this.index = null;
				this.precioModal = false;
			},
			borrar(p,index) {
				this.$store.commit('setsplash',{texto:'Borrando...', activar: true});
				Schema.models.Precio.borrar(p.id,'all',(job) => {
					this.precios.splice(index,1);
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			cerrar() {
				this.$emit('getprecios',null);
			},
			guardar() {
				if(!this.$refs.form.validate()) {
					return;
				}
				let aux = this.precio;
				aux.producto_id = this.producto.id;
				this.$store.commit('setsplash',{texto:'Guardando...', activar: true});
				Schema.models.Precio.guardar(aux,'all',(job) => {
					aux['id'] = job.newData.id;
					this.precios.push(aux);
					this.precio = {...this.newPrecio};
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			getCliente(c) {
				if(c) {
					this.precio.cliente_id = c.id;
					this.precio.p_cliente = c;
				}
			},
			precioInfo(p) {
				let cliente;
				switch(p.tipo) {
					case 'general': 
					//console.log("general precio ",p.id);
					//console.log(this.tipos_cliente);
						return this.tipos_cliente.find((tipo) => {
							return tipo.id === p.tipo_cliente_id;
						}).nombre; 
					break;
					
					case 'especial': 
					//console.log("especial precio ",p.id);
						return p.p_cliente.es_empresa?p.p_cliente.razon_social:p.p_cliente.encargado;
					break;
					
					case 'condicional': 
						return (p.fecha?(p.fecha_fin?'De ' +p.fecha + ' al ' + p.fecha_fin:'El ' + p.fecha): ' ')
						+ ' ' + (p.volumen? 'volumen ' + p.condicion + ' a ' + p.volumen + ' ' + this.producto.unidad_medida :' ');
					break;
				}
				return null;
			},
		},
		computed: {
			datosCargados() {
				return this.tipos_cliente.length > 0;
			},
		},
		watch: {},
	}
</script>
<style>
</style>
