import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class User extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = { 
      id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			username: {
				allowNull: false,
				type: DataTypes['STRING']
			},
			password: {
				allowNull: false,
				type: DataTypes['STRING']
			},
			nombre: {
				allowNull: false,
				type: DataTypes['STRING']
			},
			a_paterno: {
				allowNull: false,
				type: DataTypes['STRING']
			},
			a_materno: {
				allowNull: true,
				type: DataTypes['STRING']
			},
			activo: {
				allowNull: false,
				type: DataTypes['BOOLEAN'],
				defaultValue: true,
			},
			rol: {
				allowNull: false,
				type: DataTypes.ENUM('capturista','cajero','produccion','reparto','admin','tablajero','inventario')
			},
			created_at: {
				allowNull: true,
				type: DataTypes.DATE,
			},
			updated_at: {
				allowNull: true,
				type: DataTypes.DATE,
			}
    }; 
    this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'users',
			modelName: 'User',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [];
	}
	
	/**
   * @override
  **/
	async _findLike(data) {
		return await this.model.findAll({
			where:{ [Op.or]: [
				{ nombre: {[Op.like]:'%' + data.valor + '%'} },
				{ a_paterno: {[Op.like]:'%' + data.valor + '%'} },
				{ a_materno: {[Op.like]:'%' + data.valor + '%'} },
			] },
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
			limit:data.limit
		});
	}
	
	tablajeros(handler) { 
		this.attachSelectJob({},'_tablajeros',handler);
	}
	
	async _tablajeros(data) {
		return await this.model.findAll({
			where:{rol:'tablajero', activo:1},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
	repartidores(handler) { 
		this.attachSelectJob({},'_repartidores',handler);
	}
	
	async _repartidores(data) {
		return await this.model.findAll({
			where:{rol:'reparto', activo:1},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}

} // User
