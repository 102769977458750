import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Producto extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			nombre: {
				allowNull: false,
				type: DataTypes['STRING'](100),
			},
			clave: {
				allowNull: true,
				type: DataTypes['STRING'](70),
			},
			codigo: {
				allowNull: true,
				type: DataTypes['STRING'](70),
			},
			categoria: {
				allowNull: false,
				type: DataTypes['STRING'](15),
			},
			unidad_medida: {
				allowNull: true,
				type: DataTypes['STRING'](10),
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			},
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'productos',
			modelName: 'Producto',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{
				model: this.sequelize.models.Precio, 
				as: 'p_precios', 
				include:[
					{ model:this.sequelize.models.Cliente, 
					  as: 'p_cliente',
					  attributes: ['id',
					  'asentamiento_id',
					  'tipo_cliente_id',
					  'razon_social',
					  'encargado',
					  'es_empresa',
					  'telefono',
					  'movil',
					  'calles',
					  'andador',
					  'manzana',
					  'seccion',
					  'numero_exterior',
					  'numero_interior',
					  'notas',
					  'created_at',
					  'updated_at',
					  'id_user',],
					},
					{model:this.sequelize.models.TipoCliente, as: 'p_tipo_cliente'},
				]
			},
			//{model: this.sequelize.models.ItemPedido, as: 'p_items'},
		];
	}
	
	masVendidos(sucursal_id, handler) {
		this.attachSelectJob({sucursal_id},'_masVendidos',handler);
	}
  
  async _masVendidos(data) {
		let items = {};
		let pedidosHoy = await Schema.models.Pedido._dataSince({fecha:Helper.fecha()});
		pedidosHoy.forEach(ped => {
			ped.items.forEach(item => {
				if(!items[item.producto_id]) {
					items[item.producto_id] =  {
						producto: item.producto,
						cantidad: 0,
					}
				}
				items[item.producto_id].cantidad = Number(items[item.producto_id].cantidad) + Number(item.cantidad);
			}); 
		});
		return items;
	}
	
	/**
	 * @override
	 **/
	countN(categoria, handler) {
		this.attachSelectJob({categoria},'_countN',handler);
	}
	
	/**
	 * @override
	 **/
	
	async _countN(data) {
		return await this.model.count({
			where: { categoria: data.categoria },
		});
	}

	
	porCategoria(categoria, handler) {
		console.log('cuanros ' , categoria)
		this.attachSelectJob({categoria},'_porCategoria',handler);
	}
	
	async _porCategoria(data) {
		return await this.model.findAll({
			where:{ 
				categoria: data, // <-- buscar por categoria
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']]
		});
	}
}
