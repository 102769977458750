import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class ItemPedido extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = { 
			id: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING'],
			},
			pedido_id: {
				allowNull: false,
				type: DataTypes['STRING'],
				references: {
					model: 'Pedido',
					key: 'id'
				}
			},
			producto_id: {
				allowNull: false,
				type: DataTypes['BIGINT']
			},
			cantidad: {
				allowNull: false,
				type: DataTypes['FLOAT'],
				defaultValue: 0
			},
			nota: {
				allowNull: true,
				type: DataTypes['STRING'],
			},
			precio: {
				allowNull: false,
				type: DataTypes['FLOAT'],
				defaultValue: 0
			},
			descuento: {
				allowNull: true,
				type: DataTypes['FLOAT'],
				defaultValue: 0
			},
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
			tableName: 'items_pedidos',
			modelName: 'ItemPedido',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
		};
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [{ model: this.sequelize.models.Producto,  as:'producto' }];
	}
  //
  
  /**
   * @override
  **/
  
  async _saveAll(arrayData, sucursal_id) {
		let newData = [];
		for(let i = 0; i < arrayData.length; i++) {
			newData.push(await this._save(arrayData[i], sucursal_id));
		}
		return newData;
	}
	
	/**
   * @override
  **/
  
  async _save(data, sucursal_id) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			let idSig = await Schema.models.Id._getIdSiguiente('items',sucursal_id);
			//console.log("id,siguiente items", idSig);
			data.id = idSig  + ':' + sucursal_id;
			await Schema.models.Id._amuentarId('items', sucursal_id);
		}
		if(model) {
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					model[field] = data[field];
				}
			}
			await model.save();
		} else {
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		return model;
	}
}
