import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Cancelacion extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['INTEGER'],
      },
			pedido_id: {
        allowNull: false,
				type: DataTypes['STRING'],
				references: {
					model: 'Pedido',
					key: 'id'
				}
      },
      user_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
      },
      motivo: {
				allowNull: false,
				type: DataTypes['TEXT'],
      },
      created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
      },
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'cancelaciones',
			modelName: 'Cancelacion',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Pedido, as: 'c_pedido'},
		];
	}
  
  crearCancelacion(cancelacion, socketId, handler) {
		this.attachExecuteJob({cancelacion, socketId},'_saveCancelacion','own',handler);
	}
	
	async _saveCancelacion(data) {
		let cancelacion = await this._save(data.cancelacion);
		let pedido = await Schema.models.Pedido._find(data.cancelacion.pedido_id);
		if(!!pedido.dataValues) {
			pedido = pedido.dataValues;
		}
		pedido.cancelado = 1;
		pedido = await Schema.models.Pedido._save(pedido);
		if(this.origin == 'server') {
			this.socketIO.to(data.socketId).emit('crud_channel_client',{
				data:{cancelacion,socketId: data.socketId},
				action:'_saveCancelacion',
				broadcast:'none',
				socket_id: data.socketId,
				model:this.options.modelName,
			});
		}
		console.log("cancelacion guardada",cancelacion);
		return {cancelacion,socketId: data.socketId};
	}
	
	async _saveAll(arrayData) {
		let newData = [];
		for(let i = 0; i < arrayData.length; i++) {
			//let pedido = await Schema.models.Pedido._find(arrayData[i].pedido_id);
			let pedido = await this.sequelize.models.Pedido.findByPk(arrayData[i].pedido_id, {
				attributes: [
					'id', 'sucursal_id', 'empleado_id', 'repartidor_id', 'tablajero_id', 'cliente_id', 'estado', 'created_at',
					'fecha_in_produccion', 'fecha_out_produccion', 'fecha_reparto', 'fecha_entregado', 'fecha_finalizado', 'total',
					'pagos', 'cancelado'
				],
			});
			if(!!pedido) {
				pedido.cancelado = 1;
				await pedido.save();
				let cancelacion = await this._save(arrayData[i]);
				newData.push(await this._save(arrayData[i]));
			}
		}
		return newData;
	}
}
