<template>
	<v-card elevation="0" style="margin:4px 0 4px 0; padding:5px; ">
		<h3>Productos mas Vendidos</h3>
		<v-sheet color="rgba(0, 0, 0, .12)" >
      <v-sparkline height="100" padding="24" stroke-linecap="round" :labels="labels" :value="values">
      </v-sparkline>
    </v-sheet>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
export default {
  components:{ },
  props:{
		sucursal: {
			type:Object,
			default() { return null;}
		}
	},
  data () { return { 
		unsubscribe:null,
		items: {},
	}},
	created() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'setserverdata' && state.server_data.model == 'Pedido') {
				console.log(state.server_data);
				this.listar();
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	mounted(){
		this.listar();
	},
	methods: {
		listar() {
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Producto.masVendidos(null, job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.items = job.newData;
				console.log("que pedo", this.items);
			});
		},
		obtenerDeItems(value) {
			let chol = [];
			let idProductos = Object.keys(this.items);
			for(let i = 0; i < idProductos.length; i++) {
				let p = this.items[idProductos[i]].producto;
				let cantidad = this.items[idProductos[i]].cantidad;
				chol.push(value=='producto'?p.nombre + ' ' +cantidad+' '+p.unidad_medida:cantidad);
			}
			return chol;
		}
	},
	computed: { 
		labels() {
			return this.obtenerDeItems('producto');
		},
		values() {
			return this.obtenerDeItems('cantidad');
		},
		verBarra() {
			return Object.keys(this.items).length > 0;
		},
	},
	watch: {},
}
</script>

<style>
</style>
