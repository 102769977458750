<template>
  <v-card class="pa-1" height="210px">
		<v-row style="margin:3px 0 5px 0;padding:2px;">
			<v-col class="no-mp" cols="3">Fecha</v-col>
			<v-col class="no-mp" cols="4">
				<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
				offset-y min-width="auto" >
					<template v-slot:activator="{ on, attrs }">
						<v-text-field  label="Fecha" solo v-model="fecha"  readonly v-bind="attrs" v-on="on"
						hide-details ></v-text-field>
					</template>
					<v-date-picker  @input="menu2 = false" v-model="fecha"></v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
		<v-row style="margin:3px 0 5px 0;padding:2px;">
			<v-col class="no-mp" cols="6 ">
				<v-card class="rounded-t-xl" style="text-align:center; background-color:#D6D6D6" elevation=0>
					Requisitados
				</v-card>
			</v-col>
			<v-col class="no-mp" cols="6">
				<v-card class="rounded-t-xl" style="text-align:center; background-color:#D6D6D6" elevation=0>
					Envios a sucursales
				</v-card>
			</v-col>
		</v-row>
		<v-row class="no-mp">
			<v-col class="no-mp" cols="6">
				<v-card elevation=0 
				style="margin:0;padding3px; height:100px; overflow-x: hidden; overflow-y: scroll; background-color:#F8F8F8;">
					<v-row style="margin:0; padding:2px; font-size:10px;" v-for="e in peticion" :key="e.id">
						<v-col class="no-mp" cols="4">{{e.e_sucursal.nombre}}</v-col>
						<v-col class="no-mp" cols="4">{{e.created_at | dateTime}}</v-col>
						<v-col class="no-mp" cols="2"><v-btn x-small v-on:click="imprimir(e)"><i class="fa fa-print"></i></v-btn> </v-col>
					</v-row>
				</v-card>
				
			</v-col >
			<v-col class="no-mp" cols="6">
				<v-card elevation=0 
				style="margin:0;padding3px; height:100px; overflow-x: hidden; overflow-y: scroll; background-color:#F8F8F8;">
					<v-row style="margin:0; padding:2px; font-size:10px;" v-for="e in confirmacion" :key="e.id">
						<v-col class="no-mp" cols="4">{{e.e_sucursal.nombre}}</v-col>
						<v-col class="no-mp" cols="4">{{e.created_at | dateTime}}</v-col>
						<v-col class="no-mp" cols="2"><v-btn x-small v-on:click="imprimir(e)"><i class="fa fa-print"></i></v-btn> </v-col>
						<v-col class="no-mp" cols="2"><v-btn x-small v-on:click="cancelar(e)"><i class="fa fa-cancel"></i></v-btn> </v-col>
					</v-row>
				</v-card>
			</v-col >
		</v-row>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema'
import Helper from '../../core/Helper'
//import TicketEnvio from '../../utils/TicketEnvio'
export default {
	components:{ },
	props: { },
	data: () => ({ 
		menu2:false,
		fecha:null,
		envios:[],
	}),
	mounted() { 
		this.fecha = Helper.fecha(); 
		this.buscar();
	},
	methods: {
		imprimir(e) {
			console.log("imprminiedo",e)
			Schema.models.Envio.reimprimir(e,(job) => {});
		},
		cancelar(e) {
		},
		buscar() {
			Schema.models.Envio.creadosEnFecha(this.fecha, job => {
				this.envios = job.newData;
			});
		}
	},
	watch: {
		fecha() {
			this.buscar();
		},
	},
	computed: {
		peticion() {
			let a = [];
			this.envios.forEach((i) => {
				if(i.tipo == 'peticion' ) {
					a.push(i);
				}
			});
			return a;
		},
		confirmacion() {
			let a = [];
			this.envios.forEach((i) => {
				if(i.tipo == 'confirmacion') {
					a.push(i);
				}
			});
			return a;
		},
	},
}
</script>
<style>
</style>
