import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Envio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        primaryKey: true,
        autoIncrement: true,
        type: DataTypes['INTEGER']
      },
      sucursal_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE'],
      },
      cancelado: {
        allowNull: true,
        type: DataTypes['BOOLEAN'],
        defaultValue: false,
      },
      motivo: {
        allowNull: true,
        type: DataTypes['TEXT'],
      },
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'surtidos',
			modelName: 'Surtido',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Salida, as: 'salidas', include:[{model: this.sequelize.models.Insumo, as : 's_insumo'}]},
			{model: this.sequelize.models.Sucursal, as: 's_sucursal'},
		];
	}
	
	crearSalida(sucursal_id,salidas, handler) {
		this.attachExecuteJob({sucursal_id,salidas},'_crearSalida','own',handler);
	}
	
	async _crearSalida(data) {
		let surtido = await this._save({sucursal_id:data.sucursal_id});
		let insumosActualizar = [];
		let salidasGuardar = [];
		for(let i = 0; i < data.salidas.length; i++) {
			data.salidas[i].surtido_id = surtido.id;
			let insumo = data.salidas[i].s_insumo;
			if(Number(data.salidas[i].cantidad) > 0) {
				salidasGuardar.push(data.salidas[i]);
				insumo.existencia = Number(insumo.existencia) -  Number(data.salidas[i].cantidad);
				insumosActualizar.push(insumo);
			}
		}
		let salidas = await Schema.models.Salida._saveAll(salidasGuardar);
		let insmuos = await Schema.models.Insumo._saveAll(insumosActualizar);
		return {sucursal_id:data.sucursal_id, salidas};
	}
	
	cancelar(surtido, handler) {
		this.attachExecuteJob({surtido},'_cancelar','own',handler);
	}
	
	async _cancelar(data) {
		let salidas = await Schema.models.Salida._findBy({campo:'surtido_id',valor:data.surtido.id});
		let insumosActualizar = [];
		for(let i = 0; i < salidas.length; i++) {
			let insumo = salidas[i].s_insumo;
			insumo.existencia = Number(insumo.existencia) +  Number(salidas[i].cantidad);
			insumosActualizar.push(insumo);
		}
		let surtido = await this._save(data.surtido);
		let insmuos = await Schema.models.Insumo._saveAll(insumosActualizar);
		return {surtido};
	}
	
}
