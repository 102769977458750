const navegable =  {
	data() {return {}},
  methods: {
    enfocarEn(ref) {
			let comp = document.getElementById(ref);
			console.log(comp);
			if(comp) {
				comp.focus();
			}
		},
		navegar2(event) {
			console.log(event);
		},
		navegar(ultimo, ref, index) {
			if(index < 0) {
				index = ultimo -1;
			}
			if(index > (ultimo -1)) {
				index = 0;
			}
			this.enfocarEn(ref+index);
		},
  }
}
export default navegable;
