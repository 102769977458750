<template>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Dashboard</v-card-title>
		</v-toolbar>
		<v-card style="padding:20px;">
			<v-container>
			<v-row class="no-mp">
				<v-col cols="12" xs="12" sm="12" md="12" lg="4" >
					<v-select label="Sucursal" :items="sucursales" :item-text="'nombre'" return-object v-model="suc"></v-select>
				</v-col>
				
				<!-- -->
				<v-col cols="12" xs="6" sm="6" md="6" lg="4"  >
					<div class="d-lg-none">
					Fecha 
					</div>
					<div class="d-none d-lg-block" style="text-align:right;">
					Fecha 
					</div>
				</v-col>
				<!-- -->
				<v-col cols="12" xs="6" sm="6" md="6" lg="4" style="margin:0;padding:10px;" >
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
						offset-y min-width="auto" >
						<template v-slot:activator="{ on, attrs }">
							<v-text-field  v-model="fechaBuscar"  readonly v-bind="attrs" v-on="on" hide-details solo></v-text-field>
						</template>
						<v-date-picker  @input="menu2 = false" v-model="fechaBuscar"></v-date-picker>
					</v-menu>
				</v-col>
				<!-- -->
			</v-row>
			
			<v-row class="no-mp">
				<v-col class="no-mp" cols="12">
					<BuscadorPedido :sucursal="suc"/>
				</v-col>
			</v-row>
		</v-container>	
		</v-card>
		<v-card elevation="0" style="padding:5px;margin:5px; background-color:#F5F6FF;"> 
			<v-card style="height: 5vh; padding: 5px 0 0 10px; margin:5px; font-size:13px;" outlined>
				<v-row style="height: 5vh;" justify="space-around" align-items="align-stretch">
					<v-col cols="2" xs="2" sm="2" md="1" lg="1" >ID</v-col>
					<v-col cols="4" xs="4" sm="4" md="3" lg="3">Cliente</v-col>
					<v-col cols="1" class="d-none d-md-block">Total </v-col>
					<v-col cols="1" class="d-none d-md-block" >Atendio </v-col>
					<v-col cols="1" class="d-none d-md-block">Repartidor</v-col>
					<v-col cols="3" class="d-none d-md-block">Seguimiento del Pedido</v-col>
					<v-col cols="6" xs="4" sm="4" md="2" lg="2">Acciones</v-col>
				</v-row>
			</v-card>
			<v-card style="height: 7vh; padding: 5px 0 0 10px; margin:5px; font-size:13px;" outlined
			v-for="p,index in pedidos" :key="index" :class="pedidoEstado(p)">
				<v-row style="height: 7vh;" justify="space-around" align-items="align-stretch">
					<v-col cols="2" xs="2" sm="2" md="1" lg="1"><v-chip dark x-small color="red">{{p.id | folio}}</v-chip></v-col>
					<v-col cols="4" xs="4" sm="4" md="3" lg="3">{{nombre(p.cliente)}}</v-col>
					<v-col cols="1" class="d-none d-md-block"><v-chip dark x-small color="success">$ {{p.total | moneda}}</v-chip></v-col>
					<v-col cols="1" class="d-none d-md-block"><v-chip dark x-small>{{p.cajero.nombre}}</v-chip></v-col>
					<v-col cols="1" class="d-none d-md-block"><v-chip dark x-small v-if="!!p.repartidor">{{p.repartidor.nombre}}</v-chip></v-col>
					<v-col cols="3" class="d-none d-md-block"><PedidoEstatus :pedido="p" /></v-col>
					<v-col cols="6" xs="4" sm="4" md="2" lg="2">
						<v-btn-toggle dense> 
							<v-btn color="indigo" small :key="'impr'" v-on:click="ver(p, index)">
								<i class="fa fa-eye"></i>
							</v-btn>
							<v-btn color="orange" small :key="'reset'" v-on:click="resetear(p, index)">
								<i class="fas fa-redo"></i>
							</v-btn>
							<v-btn color="red" small :key="'can'" v-on:click="cancelar(p, index)">
								<i class="fa fa-ban"></i>
							</v-btn>

						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
		<v-pagination v-model="page" :length="paginas" :total-visible="7" ></v-pagination>
		<!-- -->
		<v-dialog v-model="infoModal" width="1000" >
			<v-card v-if="pedidoSelect" :class="pedidoSelect.estado">
				<v-card-title>Pedido folio: <v-chip dark color="red"> {{' '+pedidoSelect.id}}</v-chip></v-card-title>
				<InformacionPedido :pedido="pedidoSelect" :tipo="'info'" :puede_abonar="false"/>
				<InformacionPedido :pedido="pedidoSelect" :tipo="'historial'" :puede_abonar="false"/>
				<v-divider></v-divider>
				<v-card-actions> 
					<v-btn v-on:click="infoModal = false">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- -->
		<!-- -->
		<v-dialog v-model="cancelacionModal" width="400" >
			<CancelacionForm v-if="cancelacionModal" :pedido="pedidoSelect" v-on:getcancelacion="getCancelacion"/>
		</v-dialog>
		<!-- -->
		<!-- Modal de reseteo  -->
		<v-dialog v-model="resetModal" width="400" >
			<ReseteoForm v-if="resetModal" :pedido="pedidoSelect" v-on:getReseteo="getReseteo"/>
		</v-dialog>
		<!-- -->
	</v-card>
</template>

<script>
import MasVendidos from '../reutilizables/productos/MasVendidos';
import PedidoEstatus from '../reutilizables/pedidos/PedidoEstatus';
import InformacionPedido from '../reutilizables/pedidos/InformacionPedido';
import BuscadorPedido from '../reutilizables/pedidos/BuscadorPedido';
import CancelacionForm from '../reutilizables/cancelaciones/CancelacionForm';
import ReseteoForm from '../reutilizables/reseteos/ReseteosForm.vue'
import Schema from '../core/Schema';
import Helper from '../core/Helper';
export default {
  components: {
		MasVendidos,
		PedidoEstatus,
		InformacionPedido,
		CancelacionForm,
		BuscadorPedido,
		ReseteoForm,
	},
  data () { return { 
		menu2:false,
		fechaBuscar:null,
		page:1,
		paginas:0,
		pedidoSelect:null,
		infoModal:false,
		cancelacionModal: false,
		resetModal:false,
		pedidoSelectIndex:null,
		pedidos:[],
		sucursales:[],
		suc:null,
	}},
	mounted() {
		console.log("fecha de spa", Helper.fechaHora());
		this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
		Schema.models.Sucursal.listar(null,null,job => {
			this.sucursales = job.newData;
			this.$store.commit('setsplash',{texto:'', activar: false});
		});
	},
	methods: {
		buscarPedidos() {
			if(!this.fechaBuscar || this.fechaBuscar.length <= 0) {
				this.fechaBuscar = Helper.fecha();
			}
			if(!this.suc) {
				alert("Selecciona la sucursal");
				return;
			}
			this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
			Schema.models.Pedido.count(this.fechaBuscar,this.suc.id,job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.paginas = Math.ceil((job.newData / 20));
				Schema.models.Pedido.pedidosFechaPaginacion(this.page,20,this.suc.id, this.fechaBuscar,job => {
					this.pedidos = job.newData;
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			});
			
		},
		nombre(c) {
			return c.razon_social? c.razon_social: c.encargado;
		},
		ver(c, index) {
			this.pedidoSelect = c;
			this.pedidoSelectIndex = index;
			this.infoModal = true;
		},
		cancelar(c, index) {
			this.pedidoSelect = c;
			this.pedidoSelectIndex = index;
			this.cancelacionModal = true;
		},
		resetear(c, index){
			this.pedidoSelect = c;
			this.pedidoSelectIndex = index;
			console.log('Estacion ')
			this.resetModal = true;
		},
		getReseteo(r,p){
			console.log("recibiendo",r);
			if(!!r) {
				console.log("se hizo la cancelacion",r);
				console.log("cambiando pedido",p);
				//this.pedidos[this.pedidoSelectIndex] estado. = p.estado;
				console.log(this.pedidos[this.pedidoSelectIndex].abonos =[])
				Object.assign(this.pedidos[this.pedidoSelectIndex],p)
				//this.pedidos.splice(this.pedidoSelectIndex,1,p);
			}
			this.resetModal = false;
		},
		getCancelacion(c,p) {
			console.log("recibiendo",c);
			if(!!c) {
				console.log("se hizo la cancelacion",c);
				console.log("cambiando pedido",p);
				this.pedidos.splice(this.pedidoSelectIndex,1,p);
			}
			this.cancelacionModal = false;
		},
		pedidoEstado(p) {
			return p.cancelado? 'cancelado':p.estado;
		},
		
	},
	computed: { },
	watch: {
		suc() {
			this.buscarPedidos();
			this.pedidoSelect = null;
		},
		fechaBuscar() {
			this.buscarPedidos();
			this.pedidoSelect = null;
		},
		page() {
			this.buscarPedidos();
		},
	},
}
</script>

<style>
</style>
