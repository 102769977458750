import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Pedido extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = { 
			id: {
				allowNull: false,
				primaryKey: true,
				type: DataTypes['STRING'],
			},
			sucursal_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'Sucursal',
					key: 'id'
				}
			},
			empleado_id: {
				allowNull: false,
				type: DataTypes['INTEGER'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			repartidor_id: {
				allowNull: true,
				type: DataTypes['INTEGER'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			cliente_id: {
				allowNull: false,
				type: DataTypes['BIGINT'],
				references: {
					model: 'Cliente',
					key: 'id'
				}
			},
			tablajero_id: {
				allowNull: true,
				type:DataTypes['BIGINT'],
				references: {
					model: 'User',
					key: 'id'
				}
			},
			estado: {
				allowNull: false,
				type: DataTypes['ENUM']('apertura','produccion', 'armado','reparto','cobro','finalizado')
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			},
			fecha_in_produccion: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_out_produccion: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_reparto: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_entregado: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			fecha_finalizado: {
				allowNull: true,
				type: DataTypes['DATE']
			},
			total: {
				allowNull: false,
				type: DataTypes['FLOAT']
			},
			descuento: {
				allowNull: true,
				type: DataTypes['FLOAT']
			},
			pagos: {
				allowNull: false,
				type: DataTypes['FLOAT']
			},
			cancelado: {
				allowNull: true,
				type: DataTypes['BOOLEAN'],
				defaultValue: false,
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'pedidos',
			modelName: 'Pedido',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Cancelacion, as: 'cancelacion'},
			{model: this.sequelize.models.User, as: 'repartidor'},
			{model: this.sequelize.models.User, as: 'cajero'},
			{model: this.sequelize.models.Impresion, as: 'impresion'},
			{model: this.sequelize.models.User, as: 'tablajero'},
			{model: this.sequelize.models.Tarjeta, as: 'tarjeta'},			
			{model: this.sequelize.models.ItemPedido, as: 'items', include:[{model: this.sequelize.models.Producto, as: 'producto'}]},
			{model: this.sequelize.models.PagoPedido, as: 'abonos', include:[{model: this.sequelize.models.User, as: 'cobrador'}]},
			{model: this.sequelize.models.Cliente, attributes: ['id',
			'asentamiento_id',
			'tipo_cliente_id',
			'razon_social',
			'encargado',
			'es_empresa',
			'telefono',
			'movil',
			'calles',
			'andador',
			'manzana',
			'seccion',
			'numero_exterior',
			'numero_interior',
			'notas',
			'created_at',
			'updated_at',
			'id_user',], as: 'cliente', include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]},
		];
	}
  // guardar sin update
  guardarSinUpdate(data, broadcast,handler) {
	this.attachExecuteJob(data,'_saveSU',broadcast,handler);
  }	
  //
  async _saveSU(data) {
	if(!!data.dataValues) {
		data = data.dataValues;
	}
	let model = null;
	if(!!data[this.primaryKey]) {
		model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
	} 
	if(model) {
		if(this.fieldsValids.includes('updated_at')) {
			data['updated_at']= Helper.fechaHora();
		}
		//console.log('primer it ',data.created_at)
		for(let field in data) {
			if(this.fieldsValids.includes(field)) {
				if(field != 'created_at'){
					model[field] = data[field];
				}
			}
		}
		//console.log('pasando it ',model['created_at'])
		await model.save();
	} else {
		if(this.fieldsValids.includes('created_at')) {
			data['created_at']= Helper.fechaHora();
		}
		if(this.fieldsValids.includes('updated_at')) {
			data['updated_at']= Helper.fechaHora();
		}
		model = await this.model.create(data);
	}
	if(!!model.dataValues) {
		model = model.dataValues;
	}
	//if(this.fieldsValids.includes('created_at')) {
	//	model.created_at= Helper.fechaToString(model.created_at);
	//}
	if(this.fieldsValids.includes('updated_at')) {
		model.updated_at = Helper.fechaToString(model.updated_at);
	}
	return model;
}  
  //
  crearPedido(pedido,items, handler) {
		this.attachExecuteJob({pedido,items},'_savePedido','none',handler);
	}
  //
  
  async _savePedido(data) {
		let p = await this._save(data.pedido);
		let items = [];
		for(let i = 0; i < data.items.length; i++) {
			let item = data.items[i];
			item.pedido_id = p.id;
			items.push(item);
		}
		items = await Schema.models.ItemPedido._saveAll(items,p.sucursal_id);
		return {pedido:p, items};
	}
	
	//
	abonar(pedido,abono,handler) { 
		this.attachExecuteJob({pedido,abono},'_abonar','none',handler);
	}
  //
  
  //
	async _abonar(data) { 
		if(!!data.pedido.dataValues) {
			data.pedido = data.pedido.dataValues;
		}
		let pedidoBuscado = await this.model.findByPk(data.pedido.id,{attributes: this.fieldsValids});
		data.pedido.pagos = Number(pedidoBuscado.pagos);
		let restante = Number(pedidoBuscado.total) - Number(pedidoBuscado.pagos);
		let abono = null;
		if(Number(data.abono.total) <= restante ) {
			if(!data.abono.id || !data.abono.pedido_id) { // el abono es nuevo y se le suma al pago pedido
				data.abono.pedido_id = data.pedido.id;
				data.pedido.pagos += Number(data.abono.total);
			}
			abono =  await Schema.models.PagoPedido._save(data.abono,data.pedido.sucursal_id);
			if(Number(data.pedido.pagos) == Number(data.pedido.total)) {
				data.pedido.estado = 'finalizado';
				data.pedido.fecha_finalizado = Helper.fechaHora();
			}
			
		}
		let pedido = await this._save(data.pedido);
		return {pedido, abono};
	}
  //
  
  /**
   * @override
  **/
  async _save(data) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			// es aqui
			const idNew = {
				sucursal : 'Matriz',
				datos : 'vacio',
				id: null,
			}
			
			const idNewGenerate = await Schema.models.IdPedido._save(idNew);
			const idSig = idNewGenerate.id
			//let idSig = await Schema.models.Id._getIdSiguiente('pedidos',data.sucursal_id);
			data.id = idSig  + ':' + data.sucursal_id;
			//await Schema.models.Id._amuentarId('pedidos', data.sucursal_id);
		}
		if(model) {
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					model[field] = this.normalizarCampo(model,data,field);
				}
			}
			await model.save();
		} else {
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					data[field] = this.normalizarCampoEntrada(data,field);
				}
			}
			data['created_at']= Helper.fechaHora();
			
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		console.log(" despues de guardar",model);
		return this.normalizarFechasSalida(model);
	}

	async _saveInsertarPedido(data) {
		 
		let model = null;
		// obtiene el id siguiente 
		// crear el siguiente id 
		const idNew = {
			sucursal : 'Matriz',
			datos : 'vacio',
		}
		console.log('Como va ')
		const idNewGenerate = await Schema.models.IdPedido._save(idNew);
		const idSig = idNewGenerate.id
		//let idSig = await Schema.models.Id._getIdSiguiente('pedidos',data.pedido.sucursal_id);
		data.pedido.id = idSig  + ':' + data.pedido.sucursal_id;
		await Schema.models.Id._amuentarId('pedidos', data.pedido.sucursal_id); 
		// normaliza los campos de pedido 	
		for(let field in data.pedido) {
			if(this.fieldsValids.includes(field)) {
				data.pedido[field] = this.normalizarCampoEntrada(data.pedido,field);
			}
		}
		data.pedido['created_at']= Helper.fechaHora();
		model = await this.model.create(data.pedido);
		console.log ('ya guardo ')
		console.log (model)

		if(!!model.dataValues) {
			model = model.dataValues;
		}
        //return this.normalizarFechasSalida(model); //regresa un modelo pedido
		//let p = await this._save(data.pedido);
		let items = [];
		for(let i = 0; i < data.items.length; i++) {
			let item = data.items[i];
			item.pedido_id = model.id;
			items.push(item);
		}
		
		items = await Schema.models.ItemPedido._saveAll(items,model.sucursal_id);
		return {pedido:model, items};
	}
	
	normalizarCampoEntrada(data, campo) {
		let fechas = ['fecha_in_produccion','fecha_out_produccion','fecha_reparto','fecha_entregado','fecha_finalizado'];
		if (fechas.includes(campo)) { // es un campo de tipo fecha
			if(!!data[campo]) {
				return Helper.fechaHora();
			}
		} else {
			return data[campo];
		}
	}
	
	normalizarCampo(model,data, campo) {
		let fechas = ['fecha_in_produccion','fecha_out_produccion','fecha_reparto','fecha_entregado','fecha_finalizado'];
		if (fechas.includes(campo)) { // es un campo de tipo fecha
			if(!!model[campo]) {
				return model[campo];
			} else if(!!data[campo]) {
				return Helper.fechaHora();
			}
		} else {
			return data[campo];
		}
	}
	
	normalizarFechasSalida(model) {
		model.created_at = Helper.fechaToString(model.created_at);
		model.fecha_entregado = !!model.fecha_entregado? Helper.fechaToString(model.fecha_entregado):null;
		model.fecha_finalizado = !!model.fecha_finalizado? Helper.fechaToString(model.fecha_finalizado):null;
		model.fecha_in_produccion = !!model.fecha_in_produccion? Helper.fechaToString(model.fecha_in_produccion):null;
		model.fecha_out_produccion = !!model.fecha_out_produccion? Helper.fechaToString(model.fecha_out_produccion):null;
		model.fecha_reparto = !!model.fecha_reparto? Helper.fechaToString(model.fecha_reparto):null;
		return model;
	}
  
  pedidosDeHoy(sucursal_id,handler) {
		this.attachSelectJob(sucursal_id,'_pedidosDeHoy',handler);
	}
  
  async _pedidosDeHoy(sucursal_id) { 
		return await this.model.findAll({
			where:{ 
				[Op.or]: [
					{[Op.or]:[{estado:'reparto'}, {estado:'cobro'}]},
					{estado:'finalizado',fecha_finalizado:{[Op.gte]:Helper.fecha()}, repartidor_id:{[Op.not]: null }}
				],
				cancelado: 0, // 
				sucursal_id: sucursal_id, // <-- checar si una sucursal cobra o visualiza pedido de otra sucursal
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']]
		});
	}
  //
  
  /**
   * @override
  **/
  async _findBy(data) { 
		let where = {};
		if(data.campo === 'fecha') {
			where = {created_at:{[Op.between]: [data.valor + ' 00:00:00', data.valor + ' 23:59:59']}};
		} else {
			where = {[data.campo]:data.valor};
		}
		
		return await this.model.findAll({
			where,
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['id', 'ASC']]
		});
	}
	
	
	/**
   * @override
  **/
	async _saveAll(data) {
		return {
			pedidos: super._saveAll(data.pedidos),
			items: Schema.models.ItemPedido._saveAll(data.items),
			abonos: Schema.models.PagoPedido._saveAll(data.abonos),
		}
	}
	
	/**
   * @override
  **/
  async enviarDatosAlCliente(socket,fecha, sucursalId) {
		let pedidos = await this.model.findAll({
			where: {
				created_at: {[Op.gte]:fecha},
				cancelado: 1,
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
		console.log("enviar al cliente " + this.options.modelName, pedidos.length);
		let data = {
			pedidos,
			items: [],
			abonos: [],
		};
		
		pedidos.forEach(p => {
			p.items.forEach( (i) => {
				data.items.push(i);
			});
			p.abonos.forEach( (a) => {
				data.abonos.push(a);
			});
		});
		socket.emit('crud_channel_client',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:socket.id,
			broadcast:'own',
			model:'Pedido',
		});
	}
	
	/**
   * @override
  **/
  async enviarDatosAlServer(fecha) {
		let pedidos = await this._dataSince({fecha});
		console.log("enviar al server " + this.options.modelName, pedidos.length);
		let data = {
			pedidos,
			items: [],
			abonos: [],
		};
		pedidos.forEach(p => {
			p.items.forEach( (i) => {
				data.items.push(i);
			});
			p.abonos.forEach( (a) => {
				data.abonos.push(a);
			});
		});
		this.socketCli.emit('crud_channel_server',{
			data,
			newData: data,
			action:'_saveAll',
			socket_id:this.socketCli.id,
			broadcast:'own',
			model:'Pedido',
		});
	}
  //
	
	/**
   * @override
  **/
  async _dataSince(data) {
		return await this.model.findAll({
			where: { [Op.or]: {
				'created_at': {[Op.gte]:data.fecha}, 
				'fecha_in_produccion': {[Op.gte]:data.fecha},
				'fecha_out_produccion': {[Op.gte]:data.fecha},
				'fecha_reparto': {[Op.gte]:data.fecha},
				'fecha_entregado': {[Op.gte]:data.fecha},
				'fecha_finalizado': {[Op.gte]:data.fecha},
			}},
			attributes: this.fieldsValids,
			include:[
				{model: this.sequelize.models.ItemPedido, as: 'items',include:[{model: this.sequelize.models.Producto, as: 'producto'}]},
				{model: this.sequelize.models.PagoPedido, as: 'abonos'},
			],
			order: [['created_at', 'ASC']],
		});
	}
  //
  
  pedidosDiaSucursal(sucursal_id,fecha,handler) { 
		this.attachSelectJob({sucursal_id,fecha},'_pedidosDiaSucursal',handler);
	}
  
  async _pedidosDiaSucursal(data) {
		return await this.model.findAll({
			where: {
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']],
		});
	}
  //
  
  pedidosFechaPaginacion(page,limit, sucursal_id,fecha,handler) { 
		this.attachSelectJob({page,limit,sucursal_id,fecha},'_pedidosFechaPaginacion',handler);
	}
  
  async _pedidosFechaPaginacion(data) {
		return await this.model.findAll({
			where: {
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			limit:data.limit,
			offset:((data.page - 1) * data.limit),
			order: [['created_at', 'ASC']],
		});
	}
	
	aperturados(handler) { 
		this.attachSelectJob({},'_aperturados',handler);
	}
  
  async _aperturados(data) {
		return await this.model.findAll({
			where: { cancelado: 0, estado:'apertura'},
			attributes: this.fieldsValids,
			limit:10,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']],
		});
	}
	
	producidos(handler) { 
		this.attachSelectJob({},'_producidos',handler);
	}
  
  async _producidos(data) {
		return await this.model.findAll({
			where: { cancelado: 0, estado:'produccion'},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']],
		});
	}
	
	cobro(fecha,handler) { 
		this.attachSelectJob({fecha},'_cobro',handler);
	}
  
  async _cobro(data) {
		return await this.model.findAll({
			where: { 
				cancelado: 0, 
				estado:'cobro',
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']}
			},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']],
		});
	}
	
	count(fecha,sucursal_id,handler) {
		this.attachSelectJob({fecha,sucursal_id},'_count',handler);
	}
	
	async _count(data) {
		return await this.model.count( {where: {
			created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
			sucursal_id:data.sucursal_id,
		}});
	}
	
	enviarReportes() {
		
	}
	
	creadosEnFecha(fecha,sucursal_id,handler) {
		this.attachSelectJob({fecha,sucursal_id},'_createdIn',handler);
	}
	
	async _createdIn(data) {
		return await this.model.findAll( {
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [['created_at', 'ASC']],
			where: {
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
				//cancelado:0,
			}
		});
	}
	
	porFechasSucursal(fecha1, fecha2,sucursal_id,handler) {
		this.attachSelectJob({fecha1, fecha2,sucursal_id},'_porFechasSucursal',handler);
	}
	
	async _porFechasSucursal(data) {
		return await this.model.findAll( {
			attributes: this.fieldsValids,
			include: [
				{model: this.sequelize.models.User, as: 'repartidor'},
				{model: this.sequelize.models.User, as: 'cajero'},
				{model: this.sequelize.models.Tarjeta, as: 'tarjeta'},
				{model: this.sequelize.models.Impresion, as: 'impresion'},	
				{model: this.sequelize.models.Cliente,
					attributes: ['id',
						'asentamiento_id',
						'tipo_cliente_id',
						'razon_social',
						'encargado',
						'es_empresa',
						'telefono',
						'movil',
						'calles',
						'andador',
						'manzana',
						'seccion',
						'numero_exterior',
						'numero_interior',
						'notas',
						'created_at',
						'updated_at',
						'id_user',] 
					, as: 'cliente', include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]}
			],
			where: {
				created_at:{[Op.between]: [data.fecha1 + ' 00:00:00', data.fecha2 + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
				//cancelado:0,
			}
		});
	}

	porFechasSucursalProducidos(fecha1, fecha2,sucursal_id,handler) {
		this.attachSelectJob({fecha1, fecha2,sucursal_id},'_porFechasSucursalProducidos',handler);
	}
	
	async _porFechasSucursalProducidos(data) {
		return await this.model.findAll( {
			attributes: this.fieldsValids,
			include: [
				{model: this.sequelize.models.User, as: 'repartidor'},
				{model: this.sequelize.models.User, as: 'cajero'},
				{model: this.sequelize.models.Tarjeta, as: 'tarjeta'},
				{model: this.sequelize.models.Impresion, as: 'impresion'},
				{model: this.sequelize.models.Cliente,attributes: ['id',
				'asentamiento_id',
				'tipo_cliente_id',
				'razon_social',
				'encargado',
				'es_empresa',
				'telefono',
				'movil',
				'calles',
				'andador',
				'manzana',
				'seccion',
				'numero_exterior',
				'numero_interior',
				'notas',
				'created_at',
				'updated_at',
				'id_user',], as: 'cliente', include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]}
			],
			where: {
				created_at:{[Op.between]: [data.fecha1 + ' 00:00:00', data.fecha2 + ' 23:59:59']},
				cancelado: 0, estado:'produccion',
				sucursal_id:data.sucursal_id,
				//cancelado:0,
			}
		});
	}
	
	porFechaRepartidor(fecha, repartidor_id,sucursal_id,handler) {
		this.attachSelectJob({fecha, repartidor_id,sucursal_id},'_porFechaRepartidor',handler);
	}
	
	async _porFechaRepartidor(data) {
		let pedidos =  await this.model.findAll( {
			attributes: this.fieldsValids,
			include: [
				{model: this.sequelize.models.User, as: 'repartidor'},
				{model: this.sequelize.models.User, as: 'cajero'},
				{
          model: this.sequelize.models.Cliente,
          attributes: ['asentamiento_id', 'tipo_cliente_id', 'razon_social', 'encargado', 'es_empresa', 'telefono', 'movil', 'calles',
          'andador', 'manzana', 'seccion', 'numero_exterior', 'numero_interior','notas', 'id_user', 'created_at', 'updated_at'],
          as: 'cliente',
          include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]
        }
			],
			where: {
				created_at:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']},
				sucursal_id:data.sucursal_id,
				repartidor_id:data.repartidor_id,
			}
		});
		let info = {
			todos:[],
			por_repartir:[],
			por_cobrar:[],
			finalizados:[],
		};
		pedidos.forEach((p) => {
			info.todos.push(p);
			if(p.estado == 'reparto') { 
				info.por_repartir.push(p);
			}
			if(p.estado == 'cobro') { 
				info.por_cobrar.push(p);
			}
			if(p.estado == 'finalizado') { 
				info.finalizados.push(p);
			}
		});
		return info;
	}

	buscarBalance(fecha1, fecha2,cliente_id,handler) {
		this.attachSelectJob({fecha1, fecha2,cliente_id},'_buscarBalance',handler);
	}
	
	async _buscarBalance(data) {
		console.log(data)
		return await this.model.findAll( {
			attributes: this.fieldsValids,
			include: [
				{model: this.sequelize.models.User, as: 'repartidor'},
				{model: this.sequelize.models.User, as: 'cajero'},
				{model: this.sequelize.models.Tarjeta, as: 'tarjeta'},	
				{model: this.sequelize.models.Cliente,attributes: ['id',
				'asentamiento_id',
				'tipo_cliente_id',
				'razon_social',
				'encargado',
				'es_empresa',
				'telefono',
				'movil',
				'calles',
				'andador',
				'manzana',
				'seccion',
				'numero_exterior',
				'numero_interior',
				'notas',
				'created_at',
				'updated_at',
				'id_user',], as: 'cliente', include:[{model: this.sequelize.models.Asentamiento, as: 'asentamiento'}]}
			],
			where: {
				created_at:{[Op.between]: [data.fecha1 + ' 00:00:00', data.fecha2 + ' 23:59:59']},
				cancelado: 0, //estado:'produccion',
				cliente_id: data.cliente_id,
				//cancelado:0,
			}
		});
	}
  
}
