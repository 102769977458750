import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Envio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        primaryKey: true,
        type: DataTypes['STRING']
      },
      cajero_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      sucursal_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      tipo: {
				allowNull: false,
        type: DataTypes['ENUM']('peticion','confirmacion')
      },
      impreso: {
				allowNull: true,
        type: DataTypes['BOOLEAN'],
        defaultValue: false
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE'],
      },
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'envios',
			modelName: 'Envio',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.InsumoEnvio, as: 'e_insumos', include:[{model: this.sequelize.models.Insumo, as : 'insumo'}]},
			{model: this.sequelize.models.Sucursal, as: 'e_sucursal'},
			{model: this.sequelize.models.CancelacionEnvio, as: 'e_cancelacion'},
		];
	}
	
	/**
   * @override
  **/
  async _save(data) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			let idSig = await Schema.models.Id._getIdSiguiente('envios',data.sucursal_id);
			data.id = idSig  + ':' + data.sucursal_id;
			await Schema.models.Id._amuentarId('envios', data.sucursal_id);
		}
		if(model) {
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					model[field] = data[field];
				}
			}
			await model.save();
		} else {
			if(!data['created_at'] || data['created_at'] == null) {
				data['created_at']= Helper.fechaHora();
			}
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		model.created_at= Helper.fechaToString(model.created_at);
		return model;
	}
	
	crearEnvio(envio,items, handler) {
		this.attachExecuteJob({envio,items},'_saveEnvio','none',handler);
	}
  //
  
  async _saveEnvio(data) {
		let p = await this._save(data.envio);
		let items = [];
		for(let i = 0; i < data.items.length; i++) {
			let item = data.items[i];
			item.envio_id = p.id;
			items.push(item);
		}
		items = await Schema.models.InsumoEnvio._saveAll(items,p.sucursal_id);
		return {envio:p, items};
	}
	
	reimprimir(envio,handler) {
		this.attachExecuteJob({envio},'_reimprimirEnvio','all',handler);
	}
  //
  
  async _reimprimirEnvio(data) {
		return data;
	}
	
	/**
	 * Solo se implementa del lado del server
	 * **/
	canalEspecial() {
		this.socketCli.on('envio_channel_server',(data, callback) => {
			this.validaciones({data, action:'_saveEnvio'}).then((validate) => {
				if(validate.error) {
					callback(validate);
				} else {
					this._saveEnvio(data).then(newData => {
						callback(newData);
						//this.broadcast(this.createJob(newData,'_saveEnvio','all'));
					});
				}
			});
		});
	}
	
	guardarEnServer(envio,items, handler) {
		this.socketCli.emit('envio_channel_server', {envio, items}, (response) => {
			if(!!response.error) {
				handler(response);
				return;
			} else {
				this._saveEnvio(response).then(handler);
			}
		});
	}
	
	/**
   * @override
  **/
  async _dataSince(data) {
		let where = {};
		if(this.fieldsValids.includes('created_at') && this.fieldsValids.includes('updated_at')) {
			where[Op.or] = {'created_at': {[Op.gte]:data.fecha}, 'updated_at': {[Op.gte]:data.fecha}};
		} else if(this.fieldsValids.includes('created_at')) {
			where['created_at'] =  {[Op.gte]:data.fecha};
		} else if(this.fieldsValids.includes('updated_at')) {
			where['updated_at'] = {[Op.gte]:data.fecha};
		} else {
			return [];
		}
		return await this.model.findAll({
			where,
			attributes: this.fieldsValids,
			include: [{model: this.sequelize.models.InsumoEnvio, as: 'e_insumos'}],
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
}
