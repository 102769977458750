<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{sucursal.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-text-field solo label="Nombre" v-model="sucursal.nombre"></v-text-field>
						<v-text-field solo label="Direccion" v-model="sucursal.direccion"></v-text-field>
						<v-text-field solo label="Telefono" v-model="sucursal.telefono" ></v-text-field>
						<v-text-field solo label="Encargado" v-model="sucursal.encargado" ></v-text-field>
						<v-text-field solo label="RFC" v-model="sucursal.rfc" ></v-text-field>
						<v-switch solo label="Activa" v-model="sucursal.activa" ></v-switch>
						<v-select label="Tipo" v-model="sucursal.tipo" :items="['caja','operatel']"></v-select>
						<v-divider></v-divider>
						<v-expansion-panels
							multiple
						>
						<v-expansion-panel >
							<v-expansion-panel-header>
								<div class="text-subtitle-2 transition-swing m-2 p-2"  >
									Promocion para sucursal 
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
									<v-switch solo label="Descuento" v-model="sucursal.con_descuento" ></v-switch>
									<div class="text-subtitle-2 transition-swing m-2 p-2"  >
										Porcentaje de descuento
									</div>
									<v-text-field solo label="Porcentaje de descuento" v-model="sucursal.porciento_descuento" ></v-text-field>
									<div class="text-subtitle-2 transition-swing m-2 p-2"  >
										Monto minimo de compra 
									</div>
									<v-text-field solo label="Minimo minimo de compra" v-model="sucursal.cantidad_limite" ></v-text-field>
									<v-combobox
										:items="categorias"
										v-model="select"
										hide-selected
										label="agrege las categorias incluidas"
										multiple
										persistent-hint
										small-chips
										>
										<template v-slot:no-data>
											<v-list-item>
											<v-list-item-content>
												<v-list-item-title>
												No hay categorias"<strong>busqueda</strong>". Press <kbd>enter</kbd> to create a new
												</v-list-item-title>
											</v-list-item-content>
											</v-list-item>
										</template>
										</v-combobox>
							</v-expansion-panel-content>
						</v-expansion-panel>
						</v-expansion-panels>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			sucursal: {
				type:Object,
				default() { return {
					id:null,
					nombre:null,
					direccion:null,
					telefono:null,
					encargado:null,
					rfc:null,
					activa:null,
					lat:null,
					lng:null,
					tipo:null,
					con_descuento: false,
					porciento_descuento: null,
					cantidad_limite:null,
					categorias:'',
					
				};}
			}
		},
    data () { return { 
		select: [],
        items: [
          'Programming',
          'Design',
          'Vue',
          'Vuetify',
        ],
		categorias:['RES', 'CERDO', 'LACTEOS', 'EMBUTIDOS','OTROS','PROMOCIONES','POLLO','PEZ','BIMBO','VERDURAS','ABARROTES'],
	 };},
		mounted() {
			console.log('Es ',this.sucursal.id != null)
			if(this.sucursal.id != null){
				if(this.sucursal.categorias == null){
					this.sucursal.categorias =[]
				}else{
                    this.select = JSON.parse(this.sucursal.categorias)
				}
			}
		},
    methods: {
			guardar() {
				console.log(this.select.join())
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				this.sucursal.categorias = JSON.stringify(this.select)
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Sucursal.guardar(this.sucursal,'all',(job) => {
					this.$emit('getsucursal',job.newData);
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			cerrar() {
				this.$emit('getsucursal',null);
			}
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
