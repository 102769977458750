<template>
<div>
   <v-app id="inspire" style="background-color:#FFFFFF" v-if="listo">
	   <div v-if="logeado">
    <v-navigation-drawer
      v-model="drawer"
	  color="#123356" dark
      app
    >
      	<v-list-item class="no-mp"> 
         <v-list-item-content class="no-mp text-center">
			<div >
				<img :src="logo" style="width:60px;" />
			</div>
         </v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list v-if="esAdmin">
				<v-card v-for="i in menuItems" :key="i.id+''+i.nombre" v-on:click="accion(i)" elevation="0" 
				style="margin:6px; padding:5px; background-color:#154272" >
					<v-row class="no-mp">
						<v-col style="font-size:13px; margin:0; padding:0;" cols="2"><i :class="'fa fa-'+i.icon"></i></v-col>
						<v-col style="font-size:12px; margin:0; padding:0;" cols="10">{{i.nombre}}</v-col>
					</v-row>
				</v-card>
			</v-list>
			<v-divider></v-divider>
			<v-list>
				<v-card v-for="i in menuInventario" :key="i.id+''+i.nombre" v-on:click="accion(i)" elevation="0" 
				style="margin:6px; padding:5px; background-color:#154272" >
					<v-row class="no-mp">
						<v-col style="font-size:13px; margin:0; padding:0;" cols="2"><i :class="'fa fa-'+i.icon"></i></v-col>
						<v-col style="font-size:12px; margin:0; padding:0;" cols="10">{{i.nombre}}</v-col>
					</v-row>
				</v-card>
			</v-list>
    </v-navigation-drawer>
    
    <v-app-bar class="cabecera" dark app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title v-resize="anchoget">CARNICERIA CRUVI {{ancho}}</v-toolbar-title>
	  <v-spacer></v-spacer>
			<v-btn color="warning" fab dark elevation="0" v-on:click="cerrarSesion">
				<i class="fa fa-user"></i>
			</v-btn>
    </v-app-bar>
    </div>
    <v-main v-if="!logeado">
			<Login class="mt-16 mx-lg-auto" v-on:onlogin="onLogin"/>
	</v-main> 
    <v-main v-else>
			<v-window vertical v-model="window">
				<!-- vistas principales-->
				<v-window-item>  <DashBoard v-if="window == 0"/> </v-window-item>
				<v-window-item>  <Clientes v-if="window == 1"/> </v-window-item>
				<v-window-item>  <Usuarios v-if="window == 2"/> </v-window-item>
				<v-window-item>  <TipoDeClientes v-if="window == 3"/> </v-window-item>
				<v-window-item>  <Productos v-if="window == 4"/> </v-window-item>
				<v-window-item>  <AsignarColonias v-if="window == 5"/> </v-window-item>
				<v-window-item>  <Sucursales v-if="window == 6"/> </v-window-item>
				<!-- vistas inventario-->
				<v-window-item>  <CompraProveedores v-if="window == 7"/> </v-window-item>
				<v-window-item>  <Insumos v-if="window == 8"/> </v-window-item>
				<v-window-item>  <Proveedores v-if="window == 9"/> </v-window-item>
				<v-window-item>  <Almacenes v-if="window == 10"/> </v-window-item>
				<v-window-item>  <Requisiciones v-if="window == 11"/> </v-window-item>
				<v-window-item>  <ListaCompras v-if="window == 12"/> </v-window-item>
				<v-window-item>  <SalidasInventario v-if="window == 13"/> </v-window-item>
				<v-window-item>  <Reportes v-if="window == 14"/> </v-window-item>
				<!-- vistas extras-->
			</v-window>
		</v-main> 
		 <v-footer >
    <v-col 
      class="text-right"
      cols="12"
    >
      SPA CRM-CRUVI - <strong>Version 2.4.2</strong> - <strong>Ultima actualizacion 22/03/2023 </strong>
    </v-col>
  </v-footer>
  </v-app> 


  </div>
</template>

<script>
import Aplicacion from './utils/App'
import Login from './vistas/Login'
import DashBoard from './vistas/DashBoard'
import Clientes from './vistas/Clientes'
import Productos from './vistas/Productos'
import Usuarios from './vistas/Usuarios'
import TipoDeClientes from './vistas/TipoDeClientes'
import AsignarColonias from './vistas/AsignarColonias'
import Sucursales from './vistas/Sucursales'
import CompraProveedores from './vistas/CompraProveedores'
import ListaCompras from './vistas/ListaCompras'
import Insumos from './vistas/Insumos'
import Proveedores from './vistas/Proveedores'
import Almacenes from './vistas/Almacenes'
import Requisiciones from './vistas/Requisiciones'
import SalidasInventario from './vistas/SalidasInventario'
import Reportes from './vistas/Reportes'
import ClienteForm from './reutilizables/clientes/ClienteForm'
import Schema from './core/Schema';

export default {
  name: 'app',
  components:{
		Login,
		DashBoard,
		Clientes,
		Productos,
		Usuarios,
		AsignarColonias,
		ClienteForm,
		TipoDeClientes,
		Sucursales,
		CompraProveedores,
		Insumos,
		Requisiciones,
		Proveedores,
		Almacenes,
		ListaCompras,
		SalidasInventario,
		Reportes,
	},
  data () { return {
		listo:false,
		logeado:false,
		window:7,
		itemData: null,
		drawer:null,
		group:null,
		ancho:0,
		agregarCliente: false,
		menuItems:[
			{tipo:'vista', id:0, icon:'home',nombre:"DashBoard"}, 
			{tipo:'vista', id:2, icon:'user',nombre:"Usuarios"}, 
			{tipo:'vista', id:1, icon:'users',nombre:"Clientes"}, 
			{tipo:'over', id:0, icon:'user',nombre:"Crear cliente"}, 
			{tipo:'vista', id:3, icon:'list',nombre:"Tipo clientes"},
			{tipo:'vista', id:4, icon:'drumstick-bite',nombre:"Productos"}, 
			{tipo:'vista', id:5, icon:'map-marker',nombre:"Asentamientos"}, 
			{tipo:'vista', id:6, icon:'industry',nombre:"Sucursales"}, 
			{tipo:'vista', id:14, icon:'file-excel',nombre:"Reportes"}, 
			
			//{tipo:'vista', id:5, icon:'map-marker',nombre:"Asignar Colonias"},
		], 
		menuInventario:[
			{tipo:'vista', id:7, icon:'home',nombre:"Compras a proveedores"}, 
			{tipo:'vista', id:12, icon:'dollar-sign',nombre:"Compras Realizadas"}, 
			{tipo:'vista', id:8, icon:'drumstick-bite',nombre:"Insumos inventario"}, 
			{tipo:'vista', id:9, icon:'truck',nombre:"Proveedores de insumos"}, 
			{tipo:'vista', id:10, icon:'dolly-flatbed',nombre:"Almacenes de insumos"}, 
			{tipo:'vista', id:11, icon:'clipboard-list',nombre:"Salidas inventario"}, 
			{tipo:'vista', id:13, icon:'list',nombre:"Salidas Realizadas"}, 
		], 
	}},
	mounted() {
		Aplicacion.start().then(() => {
			this.listo = true;
			Schema.Auth.tieneSesion({sucursal_id:0},job => {
				if(!!job.newData && job.newData.tiene_sesion) {
					this.$store.commit('setuser',job.newData.sesion.s_user);
					if(job.newData.sesion.s_user.rol == 'admin') {
						this.window = 0;
					}
					this.logeado = true;
				}
			});
		});
	},
	methods: {
		accion(item) {
			this.itemData = item.data;
			if(item.tipo === 'vista') {
				this.window = item.id;
				console.log("ventana",this.window );
			}
			if(item.tipo === 'over') {
				switch(item.id) {
					case 0: this.agregarCliente = true; break;
				};
			}
		},
		cerrarFormCliente() {
			this.agregarCliente = false;
		},
		cerrarSesion() {
			Schema.Auth.cerrarSesion();
			this.logeado = false;
		},
		onLogin(data) {
			console.log('agregando susuario', data.user);
			this.$store.commit('setuser',data.user);
			if(data.user.rol == 'admin') {
				this.window = 0;
			}
			this.logeado = true;
		},
		anchoget(){
			this.ancho = screen.width;
	    }
	},
	computed: {
		logo() {
			return require('./imagenes/icono.png');
		},
		splash() {
			return this.$store.state.splash;
		},
		textSplash() {
			return this.$store.state.splashText;
		},
		esAdmin() {
			return !!this.$store.state.user && this.$store.state.user.rol == 'admin';
		},
	},
	watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>

<style>
</style>
