<template>
	<div>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Salidas realizadas</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-row style="margin:10px;padding:10px;">
				<v-col class="np-mp" cols="1"> Fecha </v-col>
				<v-col class="np-mp field" cols="2" >
					<input type="date" v-model="fecha" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="no-mp" cols="1"></v-col>
				<v-col class="no-mp" cols="4">
					<!--v-btn v-on:click="descargar" color="success">Descargar</v-btn-->
				</v-col>
			</v-row>
			<v-card style="background-color:transparent!important" elevation="0">
				<v-card elevation="0" style="padding:5px 17px 5px 10px;margin:0; background-color:#F8F8F8;" outlined>
					<v-row class="no-mp">
						<v-col cols="3" class="no-mp">Sucursal</v-col>
						<v-col cols="2" class="no-mp">Fecha</v-col>
						<v-col cols="2" class="no-mp">Cancelado</v-col>
						<v-col cols="1" class="no-mp"></v-col>
						<v-col cols="4" class="no-mp">Acciones</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" style="padding:1px 5px 1px 5px;margin:5px 0 0 0;background-color:rgba(16,45,80,0.1); 
				height:55vh; overflow-x: hidden; overflow-y: scroll;" outlined>
					<v-card elevation="0" style="padding:5px;margin:6px 0 0 0;" 
					v-for="(d, index) in surtidos" :key="index">
						<v-row style="padding:0px;margin:0;" >
							<v-col cols="3" class="no-mp" style="font-size:14px;">{{d.s_sucursal.nombre}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">{{d.created_at | date}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">
								<v-chip label small :color="d.cancelado?'red':'blue'">
									{{d.cancelado?'Si':'No'}}
								</v-chip>
							</v-col>
							<v-col cols="1" class="no-mp"></v-col>
							<v-col cols="4" class="no-mp">
								<v-btn-toggle>
									<v-btn x-small :key="'edi'" depressed dark color="success" v-on:click="descargar(d)">
										<i class="fa fa-download"></i> Descargar Excel
									</v-btn>
									<v-btn x-small :key="'canc'" depressed dark color="red" v-on:click="cancelar(d)" :disabled="d.cancelado">
										<i class="fa fa-times"></i> Cancelar
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-card>
		</v-card-text>
	</v-card>
		<!-- -->
		<!-- -->
		<v-dialog v-model="pagoModal" width="400" >
			
		</v-dialog>
		<!-- -->
		<v-dialog v-model="cancelModal" width="400" >
			<v-card v-if="surtidoSelect" elevation="0" outlined style="margin:0; padding:20px; background-color:#F0F0F0;">
				<v-form ref="form">
					<v-text-field solo label="Motivo" v-model="surtidoSelect.motivo"></v-text-field>
				</v-form>					
				<v-btn  color="success" v-on:click="actualizarSurtido">Guardar</v-btn>
				<v-btn  color="red" dark v-on:click="cerrar">Cerrar</v-btn>
			</v-card>
		</v-dialog>
		<!-- -->
  </div>
</template>

<script>
	import Schema from '../core/Schema';
	import Helper from '../core/Helper';
	//import PagosDeSurtido from '../reutilizables/surtidos/PagosDeSurtido';
  export default {
    components:{
			//PagosDeSurtido,
		},
    props: { },
    data () { return {
			cancelModal: false,
			pagoModal: false,
			surtidoSelect: null,
			surtidos: [],
			fecha:null,
		};},
		mounted() {
			this.fecha = Helper.fecha();
			this.buscar();
		},
    methods: {
			cancelar(d) {
				this.surtidoSelect = d;
				this.cancelModal = true;
			},
			cerrar(d) {
				this.surtidoSelect = null;
				this.cancelModal = false;
			},
			actualizarSurtido() {
				this.surtidoSelect.cancelado = true;
				if(!this.surtidoSelect.motivo || this.surtidoSelect.motivo.length <= 0) {
					alert("debes gregar un motivo");
					return;
				}
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Surtido.cancelar(this.surtidoSelect,job => {
					this.buscar();
					this.cerrar();
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			descargar(d) {
				window.open('http://reportes.carnescruvi.com.mx/excel/surtidos/'+d.id, '_blank').focus();
			},
			buscar() {
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Surtido.creadosEnFecha(this.fecha,job => {
					this.surtidos = job.newData;
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			}
		},
		computed: {},
		watch: { 
			fecha() {
				this.buscar();
			},
		}
  }
</script>
<style>
</style>
