import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Precio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['BIGINT'],
				//defaultValue: NULL
			},
			producto_id: {
				allowNull: false,
				type: DataTypes['BIGINT'],
				references: {
					model: 'Producto',
					key: 'id'
				}
			},
			monto: {
				allowNull: false,
				type: DataTypes['FLOAT'],
			},
			tipo_cliente_id: {
				allowNull: true,
				type: DataTypes['INTEGER'],
				references: {
					model: 'TipoCliente',
					key: 'id'
				}
			},
			tipo: {
				allowNull: false,
				type: DataTypes['STRING'],
			},
			cliente_id: {
				allowNull: true,
				type: DataTypes['BIGINT'],
				references: {
					model: 'Cliente',
					key: 'id'
				}
			},
			fecha: {
        allowNull: true,
        type: DataTypes['DATEONLY']
      },
      fecha_fin: {
        allowNull: true,
        type: DataTypes['DATEONLY']
      },
      condicion: {
        allowNull: true,
        type: DataTypes['STRING']
      },
      volumen: {
        allowNull: true,
        type: DataTypes['FLOAT']
      },
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			},
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
			},
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'precios',
			modelName: 'Precio',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Producto, as: 'p_producto'},
			{model: this.sequelize.models.TipoCliente, as: 'p_tipo_cliente'},
			{model: this.sequelize.models.Cliente, as: 'p_cliente'},
		];
	}
	
	async validaciones(job) {
		if(job.action == '_save') { 
			if(job.data.tipo == 'especial' && (!job.data.cliente_id || job.data.cliente_id <= 0)) {
				return {error: true, message: 'Se debe agregar un cliente para el precio especial'};
			}
		}
		return {error: false, message: ''};
	}
  
}
