<template> 
	<v-card>
		<v-toolbar color="grey" dark flat> 
			<v-btn icon v-on:click="finalizar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title> Informacion del Cliente</v-card-title>
		</v-toolbar>
    <v-card-text>
			<v-form ref="form" lazy-validation>
			<v-container fluid>
				<v-row v-if="asentaAux">
					<v-col cols="12">
							<v-card v-on:click="quitarAsentamiento" :key="'asentamientosd'"><v-card-title>{{asentamiento}}</v-card-title></v-card>
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col cols="12">
						<v-card style="margin:10px 0 0 0; padding:10px;" >
							<v-card-title> ¿Nombre del Asentamiento?</v-card-title>
							<BuscadorAsentamientos v-on:get-asentamiento="getAsentamiento"/>
						</v-card>
					</v-col>
				</v-row>
				<v-row v-if="asentaAux">
					<v-col cols="12">
						<v-text-field :rules="reglas.calles" v-model="cliente.calles" label="Calle(s)(*)"></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="asentaAux">
					<v-col> <v-text-field v-model="cliente.seccion" label="Seccion o Andador"></v-text-field> </v-col>
					<v-col> <v-text-field v-model="cliente.manzana" label="Manzana"></v-text-field> </v-col>
				</v-row>
				<v-row v-if="asentaAux">
					<v-col> 
						<v-text-field :rules="reglas.numero_exterior" v-model="cliente.numero_exterior" label="# Numero Exterior o Lote(*)"></v-text-field> 
					</v-col>
					<v-col> <v-text-field v-model="cliente.numero_interior" label="# Numero Interior"></v-text-field> </v-col>
				</v-row>
				<v-row v-if="asentaAux">
					<v-col> <v-text-field v-model="cliente.notas" label="Notas o referencias del cliente"></v-text-field> </v-col> 
				</v-row>
				<v-row>
					<v-col> <v-switch v-model="cliente.es_empresa" label="Es una Empresa" ></v-switch> </v-col>
					<v-col> <v-select v-if="editarTipo" :items="itemsTipoCliente" :item-text="'nombre'"  v-model="cliente.tipo_cliente_id"
						:item-value="'id'" label="Tipo de Cliente(*)" ></v-select> 
					</v-col>
					<v-col > 
						<v-text-field v-model="cliente.telefono" label="Telefono" ref="telefono"></v-text-field> 
					</v-col>
					<v-col > 
						<v-text-field v-model="cliente.movil" label="Movil" ref="movil"></v-text-field> 
					</v-col>
				</v-row>
				<v-row v-if="cliente.es_empresa">
					<v-col> 
						<v-text-field :rules="reglas.razon_social" v-model="cliente.razon_social" label="Razon Social(*)" :key="'rz'"></v-text-field> 
						</v-col> 
				</v-row>
				<v-row>
					<v-col> 
						<v-text-field :rules="reglas.encargado" v-model="cliente.encargado" 
						:label="labelNombre" :key="'nom'"></v-text-field> 
					</v-col> 
				</v-row>
				<v-row>
					<v-col>
						Vendedor
						 <v-autocomplete
							v-model="select"
							:rules="reglas.vendedor"
							:loading="loading"
							:items="items"
							item-text="nombre"
              				item-value="id"
							:search-input.sync="search"
							cache-items
							class="mx-4"
							flat
							hide-no-data
							hide-details
							label="Seleccione el vendedor"
							solo-inverted
							>
							<template v-slot:selection="data">
									<v-chip
									v-bind="data.attrs"
									:input-value="data.selected"
									>
									{{ data.item.nombre }} {{ data.item.a_paterno }} {{ data.item.a_materno }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template v-if="typeof data.item !== 'object'">
									 <v-list-item-content v-text="data.item"></v-list-item-content>
									</template>
									<template v-else>
									<v-list-item-content>
										<v-list-item-title >
											{{ data.item.nombre }} {{ data.item.a_paterno }} {{ data.item.a_materno }}
										</v-list-item-title>
										
									</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
					</v-col>
				</v-row>
			</v-container>
			</v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" v-on:click="finalizar()" >
        Cancelar
      </v-btn>
      <v-btn color="primary" v-on:click="guardarCliente()" >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Schema from '../../core/Schema';
import BuscadorAsentamientos from '../asentamientos/BuscadorAsentamientos.vue';
export default {
	components:{
		BuscadorAsentamientos,
	},
	props: {
		cliente: {
			type: Object,
			default() {return {
				asentamiento: null,
				asentamiento_id: null,
				tipo_cliente_id: 1,
				razon_social: null,
				encargado:null,
				es_empresa:null,
				telefono: null,
				movil: null,
				calles: null,
				andador:null,
				manzana:null,
				seccion:null,
				numero: null,
				numero_interior: null,
				numero_exterior: null,
				notas:null,
				vendedor : null,
			};},
		},
		editarTipo: {
			type: Boolean,
			default() {return true;},
		},
	},
	data: () => ({
		itemsTipoCliente:[],
		asentaAux: null,
		select: null,
		// select	
		loading: false,
        items: [ ],
        search: null,
		reglas: {
			asentamiento_id: [
				(val) => !!val || "Se requiere Asentamiento" ,
			],
			tipo_cliente_id: null,
			razon_social: [
				(val) => (!!val && val.length > 0) || "Se requiere Razon social" 
			],
			encargado:[
				(val) => (!!val && val.length > 0) || "Se requiere Nombre del cliente" ,
			],
			es_empresa:null,
			telefono: null,
			movil: null,
			calles: [
				(val) => (!!val && val.length > 0) || "Se requiere Calle o calles" ,
			],
			numero_exterior: [
				(val) => (!!val && val.length > 0) || "Numero exterior o lote" ,
			],
		},
	}),
	mounted() {
		if(this.cliente.asentamiento) {
			this.asentaAux = this.cliente.asentamiento;
		}
		Schema.models.TipoCliente.listar(null,null,result => {
			console.log("tipos de cliente", result.newData);
			this.itemsTipoCliente = result.newData;
		});
		this.items = [this.cliente.vendedor]
		this.select =  this.cliente.vendedor.id
	},
	methods: {
		getAsentamiento(a) {
			this.asentaAux =a?a:null;
		},
		clienteDefault() {
			let fields = Object.keys(this.cliente);
			for(let i = 0; i < fields.length; i++) {
				this.cliente[fields[i]] = null;
			}
		},
		guardarCliente() {
			if(!this.$refs.form.validate()) {
				//alert("Faltan agregar campos");
				return;
			}
			if(!this.asentaAux) {
				alert("Debes elegir un asentamiento para la direccion del Cliente");
				return;
			}
			this.cliente.asentamiento = this.asentaAux;
			this.cliente.asentamiento_id = this.asentaAux.id;
			this.cliente.es_empresa = this.cliente.es_empresa?1:0;
			this.cliente.id_user = this.select

			this.$store.commit('setsplash',{texto:'Cargando Precios', activar: true});
			if(Schema.models.Cliente.origin === 'client' && (!Schema.models.Cliente.socketCli || !Schema.models.Cliente.socketCli.connected)) {
				alert("No puedes crear clientes hasta conectarse con http://carnescruvi.com.mx");
				this.$store.commit('setsplash',{texto:'', activar: false});
				return;
			}
			Schema.models.Cliente.guardar(this.cliente,'all',job => {
				if(!!job.error) {
					alert(job.error);
					this.$store.commit('setsplash',{texto:'', activar: false});
					return;
				}
				this.cliente['id'] = job.newData.id;
				this.$store.commit('setsplash',{texto:'', activar: false});
				this.finalizar();
			});
		},
		finalizar() {
			this.$emit('getcliente',this.cliente);
		},
		quitarAsentamiento() {
			this.asentaAux = null;
		},
		querySelections (v) {
			this.loading = true
			// Simulated ajax query
			Schema.models.User.buscarSimilar(null,v,result => {
				console.log(result.data)
				this.items = result.data
				//this.items = result.data.filter(e => {
				//	console.log(e)
				//	return (e.nombre || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
					
				//})
				this.loading = false
			}) 
			/*
			setTimeout(() => {
			this.items = this.states.filter(e => {
				return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
			})
			this.loading = false
			}, 500)
			*/
		},
	},
	computed: {
		asentamiento() {
			if(!this.asentaAux) {
				return null;
			}
			return this.asentaAux.tipo_asentamiento + " " + this.asentaAux.asentamiento + " " +
			"C.P. "+this.asentaAux.cp + " " +
			this.asentaAux.municipio+", "+this.asentaAux.estado;
		},
		labelNombre() {
			return this.cliente.es_empresa?"Nombre del encargado":"Nombre del cliente(*)";
		}
	},
	watch: {
	search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
}
</script>
<style>
</style>
