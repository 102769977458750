<template>
	<div>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Reportes</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-row class="fila">
				<v-col class="columna" cols="4">Reporte Compras de insumos </v-col>
				<v-col class="columna" cols="6">
					<input type="date" v-model="fechaCompraInsumos" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reporteComprasInsumos"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<v-row class="fila">
				<v-col class="columna" cols="4">Reporte Salidas Insumos</v-col>
				<v-col class="columna" cols="6">
					<input type="date" v-model="fechaSalidasInsumos" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reporteSalidasInsumos"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>			
			<v-row class="fila">
				<v-col class="columna" cols="4">Reporte Inventario insumos </v-col>
				<v-col class="columna" cols="6"></v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reporteInventarioInsumos"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<v-row class="fila">
				<v-col class="columna" cols="4">Reporte Precios generales </v-col>
				<v-col class="columna" cols="6"></v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportePreciosGenerales"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<!-- Reporte de precios especiales por clientes -->
			<v-row class="fila">
				<v-col class="columna" cols="2">Reporte de precios especiales </v-col>
				<v-col class="columna" cols="2">
					
				</v-col>
				<v-col class="columna" cols="6">
					<v-autocomplete
						v-model="clienteSelec"
						:items="clientes"
						filled
						chips
						dense
						outlined
						color="blue-grey lighten-2"
						label="Seleccione un cliente"
						item-text="razon_social"
          				item-value="id"
						>
						<template v-slot:selection="data">
							<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							close
							color="#154272"
							class="white--text"
							>
							{{ data.item.razon_social }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template v-if="(typeof data.item !== 'object')">
							<v-list-item-content v-text="data.item"></v-list-item-content>
							</template>
							<template v-else>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.razon_social"></v-list-item-title>
							</v-list-item-content>
							</template>
						</template>
            		</v-autocomplete>
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportesPreciosEspeciales"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<!-- Generar Reporte de compras -->
			<v-row class="fila">
				<v-col class="columna" cols="2">Reporte compras por proveedor </v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecha1" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecha2" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="4">
					<v-autocomplete
						v-model="provselec"
						:items="proveedores"
						filled
						chips
						dense
						outlined
						color="blue-grey lighten-2"
						label="Seleccione un provedor"
						item-text="nombre"
          				item-value="id"
						>
						<template v-slot:selection="data">
							<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							close
							color="#154272"
							class="white--text"
							>
							{{ data.item.nombre }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template v-if="(typeof data.item !== 'object')">
							<v-list-item-content v-text="data.item"></v-list-item-content>
							</template>
							<template v-else>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.nombre"></v-list-item-title>
							</v-list-item-content>
							</template>
						</template>
            		</v-autocomplete>
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportesPorProvedor"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<!-- Generar Reporte de pedidos provedores por sucursal -->
			<v-row class="fila">
				<v-col class="columna" cols="2">Reporte pedidos por sucursal </v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecharsi" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecharsf" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="4">
					<v-autocomplete
						v-model="sucursalselec"
						:items="sucursales"
						filled
						chips
						dense
						outlined
						color="blue-grey lighten-2"
						label="Seleccione un sucursal"
						item-text="nombre"
          				item-value="id"
						>
						<template v-slot:selection="data">
							<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							close
							color="#154272"
							class="white--text"
							>
							{{ data.item.nombre }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template v-if="(typeof data.item !== 'object')">
							<v-list-item-content v-text="data.item"></v-list-item-content>
							</template>
							<template v-else>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.nombre"></v-list-item-title>
							</v-list-item-content>
							</template>
						</template>
            </v-autocomplete>
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportesPorSucursal"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<v-row class="fila">
				<v-col class="columna" cols="4">Reportes del dia </v-col>
				<v-col class="columna" cols="6"></v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportedelDia"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<!--Reporte de ventas por vendedorts -->
			<v-row>
			<v-col class="columna" cols="2">Reporte compras por vendedor </v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecha1v" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="2">
					<input type="date" v-model="fecha2v" class="field" data-date-format="YYYY-mm-dd">
				</v-col>
				<v-col class="columna" cols="4">
			 	
					<v-autocomplete
						v-model="select"
						:loading="loading"
						:items="items"
						item-text="nombre"
						item-value="id"
						:search-input.sync="search"
						cache-items
						class="mx-4"
						flat
						hide-no-data
						hide-details
						label="Seleccione el vendedor"
						solo-inverted
						>
						<template v-slot:selection="data">
							<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							>
							{{ data.item.nombre }} {{ data.item.a_paterno }} {{ data.item.a_materno }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template v-if="typeof data.item !== 'object'">
								<v-list-item-content v-text="data.item"></v-list-item-content>
							</template>
							<template v-else>
							<v-list-item-content>
								<v-list-item-title >
									{{ data.item.nombre }} {{ data.item.a_paterno }} {{ data.item.a_materno }}
								</v-list-item-title>
								
							</v-list-item-content>
							</template>
						</template>
					</v-autocomplete>	
				</v-col>
				<v-col class="columna" cols="2">
					<v-btn class="success" v-on:click="reportesVendedores"><i class="fa fa-download"></i> Descargar </v-btn>
				</v-col>
			</v-row>		
		</v-card-text>
	</v-card>
  </div>
</template>

<script>
import Helper from '../core/Helper'
import Api from "../utils/Api"
import Schema from '../core/Schema';
//import App from '../utils/App';

export default {
	components: { },
  props: { },
  data () { return { 
		fechaCompraInsumos:null,
		fechaSalidasInsumos:null,
		fecha1:null,
		fecha2:null,
		fecha1v:null,
		fecha2v:null,
		fecharsi:null,
		fecharsf:null,
		token: null, 
		proveedores: [],
		provselec: null,
		sucursales: [],
		sucursalselec: null,
		clientes: [],
		clienteSelec:null,
		// select clientes
		select: null , 
		loading: false,
        items: [ ],
        search: null,
	};},
  mounted() {
		this.fechaCompraInsumos = Helper.fecha();
		this.fechaSalidasInsumos = Helper.fecha();
		this.fecharsi = Helper.fecha()
		this.fecharsf = Helper.fecha()

		Schema.models.Proveedor.listar(null, null, (job) => {
			this.proveedores = job.newData;
			//this.$store.commit('setsplash',{texto:'', activar: false});
		});

		Schema.models.Sucursal.listar(null, null, (job) => {
			this.sucursales = job.newData;
			//this.$store.commit('setsplash',{texto:'', activar: false});
		});

		Schema.models.Cliente.listar(30, 1, job => {
			//this.$store.commit('setsplash',{texto:'', activar: false});
			this.clientes = job.newData;
		});
	},
  methods: {
		reporteComprasInsumos() {
			window.open('http://reportes.carnescruvi.com.mx/excel/reportecompras/'+this.fechaCompraInsumos, '_blank').focus();
		},
		reporteInventarioInsumos() {
			window.open('http://reportes.carnescruvi.com.mx/excel/reporte', '_blank').focus();
		},
		reportePreciosGenerales() {
			window.open('http://reportes.carnescruvi.com.mx/excel/precios', '_blank').focus();
		},
		reporteSalidasInsumos() {
			window.open('http://reportes.carnescruvi.com.mx/excel/salidas/'+this.fechaSalidasInsumos, '_blank').focus();
		},
		reportesVendedores(){
			
			this.login().then(async (response) => {
				if(!response.error) {
					console.log('log success', this.select)
					////*/
					if(!this.select ){
						alert('Seleccione algun vendedor')
						return 
					}
					//const idProv = this.provselec;
					const token = this.token;					
					
					this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/rep/ventas/porvendedor/${this.select}/fecha/${this.fecha1v}/fecha2/${this.fecha2v}`,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte vendedores.xlsx'
							link.click()
							self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});

		},
		reportesPreciosEspeciales(){
			this.login().then(async (response) => {
				if(!response.error) {
					console.log('log success', this.clienteSelec)
					////*/
					//const idProv = this.provselec;
					const token = this.token;

					if(!this.clienteSelec ){
						alert('Seleccione algun cliente')
						return 
					}
					
					this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/cliente/precios/${this.clienteSelec}`,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte Precios.xlsx'
							link.click()
							self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});
		},
		reportesPorProvedor(){
			this.login().then(async (response) => {
				if(!response.error) {
					console.log('log success')
					////*/
					const idProv = this.provselec;
					const token = this.token;

					if(!idProv || !this.fecha1 || !this.fecha2){
						alert('Los campos provedor y fecha son nesesarios')
						return 
					}
					
					this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/reporte/provedor/${idProv}/fecha/${this.fecha1}/fecha2/${this.fecha2}`,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte.xlsx'
							link.click()
							self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});
		},
		reportedelDia(){
			this.login().then(async (response) => {
				if(!response.error) {
					//console.log('log success')
					const token = this.token;
					
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				let self = this;
				Api({
					method: 'get',
					url: `/api/compras/deldia`,
					responseType: 'arraybuffer',
					headers: {'Authorization': 'Bearer ' + token },
					}).then(function(response) {
						const blob = new Blob([response.data], { type: 'application/zip' })
						let link = document.createElement('a')
						link.href = window.URL.createObjectURL(blob)
						link.download = 'Reporte Completo.zip'
						link.click()
						self.$store.commit('setsplash',{texto:'', activar: false});
				})
				
				}
			})		
		},
		reportesPorSucursal(){
			this.login().then(async (response) => {
				if(!response.error) {
					//console.log('log success')
					////*/
					const idSucursal = this.sucursalselec;
					const token = this.token;

					if(!idSucursal || !this.fecharsi || !this.fecharsf){
						alert('Los campos sucursal y fecha son nesesarios')
						return 
					}
					
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				let self = this;
				Api({
					method: 'get',
					url: `/api/reporte/pedidos/sucursal/${idSucursal}/fecha/${this.fecharsi}/fecha2/${this.fecharsf}`,
					responseType: 'arraybuffer',
					headers: {'Authorization': 'Bearer ' + token },
					}).then(function(response) {
						const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
						let link = document.createElement('a')
						link.href = window.URL.createObjectURL(blob)
						link.download = 'Reporte Pedidos.xlsx'
						link.click()
						self.$store.commit('setsplash',{texto:'', activar: false});
				})
				
				}
			})		
		},
		async login() {
				await Api.get('/sanctum/csrf-cookie');
				let dataLogin = await Api.post('/api/login',{username:'servicios',password:'servicios'});
				console.log("login",dataLogin.data);
				this.token = dataLogin.data.token;
				return dataLogin.data;
			},
		querySelections (v) {
			this.loading = true
			// Simulated ajax query
			Schema.models.User.buscarSimilar(null,v,result => {
				console.log(result.data)
				this.items = result.data
				this.loading = false
			}) 
		},
	},
  computed: {},
  watch: {
	search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
}
</script>
<style>
</style>
