import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
		user: null,
		server_data: null,
		sucursal: null,
		splash: null,
		splashText: null,
	},
  mutations:{
		setserverdata(state, data) {
			state.server_data = data;
		},
		setuser(state, data) {
			state.user = data;
		},
		setsucursal(state, data) {
			state.sucursal = data;
		},
		setsplash(state, data) {
			state.splash = data.activar;
			state.splashText = data.texto;
		},
	}
})
