import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './utils/store'
import VueRouter from 'vue-router'
import './assets/app.css';

import Filter from './core/Filter'
Filter.load(Vue);

Vue.use(VueRouter)
new Vue({
  el: '#app',
  store,
  vuetify,
  render: h => h(App)
})
