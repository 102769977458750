import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Compra extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        autoIncrement: true,
        primaryKey: true,
        type: DataTypes['INTEGER']
      },
      proveedor_id: {
				alluwNull: false,
        type: DataTypes['INTEGER']
      },
      fecha: {
				alluwNull: false,
        type: DataTypes['DATEONLY']
      },
      total: {
				alluwNull: false,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      pagado: {
				alluwNull: false,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      iep: {
				alluwNull: true,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      descuento: {
				alluwNull: true,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      factura: {
				alluwNull: true,
        type: DataTypes['STRING'](50),
      },
      cancelada: {
				alluwNull: true,
        type: DataTypes['BOOLEAN'],
        defaultValue: false
      },
      motivo: {
				alluwNull: true,
        type: DataTypes['STRING']
      },
      area_id: {
				alluwNull: false,
        type: DataTypes['INTEGER']
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE'],
        defaultValue: DataTypes['NOW']
      },
      updated_at: {
        allowNull: true,
        type: DataTypes['DATE'],
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'compras',
			modelName: 'Compra',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Proveedor, as: 'proveedor'},
		];
	}
  canelarCompra(compra, entradas, handler) {
		this.attachExecuteJob({compra, entradas},'_cancelarCompra','own',handler);
	}
  async _cancelarCompra(data) {
    let compra = await this._save(data.compra);
    
    console.log('aqui cancelamos compra')
    console.log(data)
  }
	
	guardarCompra(compra, entradas, handler) {
		this.attachExecuteJob({compra, entradas},'_guardarCompra','own',handler);
	}
	
	async _guardarCompra(data) {
		let compra = await this._save(data.compra);
		let insumosActualizar = [];
		let entadasGuardar = [];
		for(let i = 0; i < data.entradas.length; i++) {
			data.entradas[i].compra_id = compra.id;
			let insumo = data.entradas[i].insumo;
			if(Number(data.entradas[i].cantidad) > 0) {
				entadasGuardar.push(data.entradas[i]);
				insumo.existencia = Number(insumo.existencia) +  Number(data.entradas[i].cantidad);
				insumo.precio = Number(data.entradas[i].precio);
				insumosActualizar.push(insumo);
			}
		}
		let entradas = await Schema.models.Entrada._saveAll(entadasGuardar);
		let insmuos = await Schema.models.Insumo._saveAll(insumosActualizar);
		return {compra, entradas};
	}
	
	comprasPorFecha(fecha,handler) {
		this.attachSelectJob({fecha},'_comprasPorFecha',handler);
	}
	
	async _comprasPorFecha(data) {
		return await this.model.findAll({
			where: {fecha:{[Op.between]: [data.fecha + ' 00:00:00', data.fecha + ' 23:59:59']}},
			attributes: this.fieldsValids,
			include: this.includeAssociations(),
			order: [[this.primaryKey, 'ASC']],
		});
	}
	
}
