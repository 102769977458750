<template>
	<v-card elevation="0" style="background-color: transparent!important; margin:10px 10px 0 10px; padding:5px;">
		<v-card class="" color="#EDEDED" elevation=1>
			<v-row style="margin:0 0 5px 10px; padding:10px 0 0 0;">
				<v-col class="no-mp" cols="12" xs="12" sm="12" md="3" lg="3">
					<div style="text-align:center; padding:10px;"><h3>Buscar pedido por Folio</h3></div>
				</v-col>
				<v-col class="no-mp" cols="12" xs="12" sm="12" md="8" lg="8" v-if="!!pedidoSelect">
					<div style="padding:10px;">
						Pedido <v-chip color="red" dark small>{{pedidoSelect.id | folio}}</v-chip>
						<v-chip label color="primary">{{nombre(pedidoSelect.cliente)}}</v-chip>
					</div>
				</v-col>
				<v-col class="no-mp" cols="12" xs="12" sm="12" md="1" lg="1" v-if="!!pedidoSelect">
					<div style="padding:10px;">
						<v-btn small color="red" dark v-if="!pedidoSelect.cancelado" :key="'canc'" v-on:click="cancelar">
							<i class="fa fa-ban"></i>
						</v-btn>
						<v-btn small color="success" v-if="pedidoSelect.estado === 'reparto'" :key="'rep'" v-on:click="entregar">
							<i class="fa fa-check"></i>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-card>
		<v-card elevation=0 tile outlined >
			<v-row style="margin:0 0 5px 10px; padding:0;">
				<v-col style="margin:0;padding:5px;" cols="12" xs="12" sm="12" md="3" lg="3">
					<v-text-field v-model="valorBuscar" v-on:keyup.enter="buscarPedido" :key="'buscar_ped'" label="Folio del pedido" solo>
					</v-text-field>
					<v-btn block v-on:click="buscarPedido">Buscar</v-btn>
				</v-col>
				<v-col style="margin:0;padding:5px;" cols="12" xs="12" sm="12" md="9" lg="9" v-if="!!pedidoSelect">
					<InformacionPedidoB  :class="claseEstado()" :tipo="'historial'" :puede_abonar="false" :pedido="pedidoSelect" />
				</v-col>
			</v-row>
		</v-card>	
		<v-dialog v-model="cancelacionModal" width="400" >
			<CancelacionForm v-if="cancelacionModal" :pedido="pedidoSelect" v-on:getcancelacion="getCancelacion"/>
		</v-dialog>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
import InformacionPedidoB from './InformacionPedidoBusqueda';
import CancelacionForm from '../cancelaciones/CancelacionForm';
export default {
  components:{InformacionPedidoB,CancelacionForm},
  props: {
		sucursal:{
			type:Object,
			default() {
				return null;
			}
		}
	},
  data: () => ({
		pedidoSelect:null,
		valorBuscar: null,
		cancelacionModal:false,
	}),
	mounted() {},
  methods: {
		buscarPedido() {
			if(!this.valorBuscar || this.valorBuscar.length <= 0) {
				return;
			}
			if(!this.sucursal) {
				alert("Selecciona la sucursal primero");
				return;
			}
			let pedidoId = this.valorBuscar + ':' + this.sucursal.id;
			Schema.models.Pedido.get(pedidoId,job => {
				this.pedidoSelect = job.newData;
			});
		},
		claseEstado() {
			return this.pedidoSelect.canelado? 'cancelado':this.pedidoSelect.estado;
		},
		imprimir(p) {
		},
		estadoPedido(p) {
			return p.cancelado?'cancelado':p.estado;
		},
		direccionCompleta(c) {
			let asentamiento = '';
			if(c.asentamiento) {
				asentamiento = c.asentamiento.tipo_asentamiento + ' ' + c.asentamiento.asentamiento;
			}
			return (c.calles||'') 
			+ (c.numero_exterior?' #'+c.numero_exterior+' ':' ') 
			+ (c.numero_interior?' #INT. '+c.numero_interior+' ':' ') 
			+ (c.manzana?' MZA. '+c.manzana+' ':' ') 
			+ (c.seccion?c.seccion+' ':' ') 
			+ asentamiento;
		},
		telefonos(c) {
			let tel = '';
			if(!!c.telefono) {
				tel += ' Tel.' + c.telefono;
			}
			if(!!c.movil) {
				tel += ' Movil.' + c.movil;
			}
			return tel;
		},
		nombre(c) {
			return c.razon_social? c.razon_social: c.encargado;
		},
		direccionCompleta(c) {
			let asentamiento = '';
			if(c.asentamiento) {
				asentamiento = c.asentamiento.tipo_asentamiento + ' ' + c.asentamiento.asentamiento;
			}
			return (c.calles||'') 
			+ (c.numero_exterior?' #'+c.numero_exterior+' ':' ') 
			+ (c.numero_interior?' #INT. '+c.numero_interior+' ':' ') 
			+ (c.manzana?' MZA. '+c.manzana+' ':' ') 
			+ (c.seccion?c.seccion+' ':' ') 
			+ asentamiento;
		},
		cancelar() {
			this.cancelacionModal = true;
		},
		getCancelacion(c,p) {
			console.log("recibiendo",c);
			if(!!c) {
				console.log("se hizo la cancelacion",c);
				console.log("cambiando pedido",p);
				this.pedidoSelect.cancelado = 1;
			}
			this.cancelacionModal = false;
		},
		entregar() {
			this.pedidoSelect.estado = 'cobro';
			this.pedidoSelect.fecha_entregado = Helper.fechaHora();
			Schema.models.Pedido.guardar(this.pedidoSelect,'own',job => { });
		},
	},
	computed: {},
	watch: {}
}
</script>
<style>
</style>
