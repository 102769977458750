<template>
	<v-card style="margin:0 0 0 0;" elevation="0" >
		<v-card-text>
			<v-divider class="mb-1"></v-divider>
			<v-row class="no-mp">
				<v-col cols="12" xs="12" sm="12" md="6" lg="6">
					<v-card elevation="0" style="padding:5px; background-color:#E5E5E5">
						<v-row class="no-mp">
							<v-col class="no-mp" cols="3">Total: </v-col>
							<v-col class="no-mp" cols="3"> $ {{pedido.total| moneda}}</v-col>
							<v-col class="no-mp" cols="3">Cajero: </v-col>
							<v-col class="no-mp" cols="3"> {{pedido.cajero.nombre}}</v-col>
						</v-row>
						<v-row class="no-mp">
							<v-col class="no-mp" cols="3">Abonado: </v-col>
							<v-col class="no-mp" cols="3"> $ {{pedido.pagos| moneda}}</v-col>
							<v-col class="no-mp" cols="6" v-if="pedido.repartidor">Repartidor: {{pedido.repartidor.nombre}}</v-col>
						</v-row>
						<v-row class="no-mp">
							<v-col class="no-mp" cols="3">Restante: </v-col>
							<v-col class="no-mp" cols="3">$ {{restante| moneda}}</v-col>
							<v-col class="no-mp" cols="6"> </v-col>
						</v-row>
						<v-row class="no-mp">
							<v-col class="no-mp" cols="3">Direccion: </v-col>
							<v-col class="no-mp" cols="9">{{clienteDireccion}}</v-col>
						</v-row>
						
						<v-divider ></v-divider>
						
						<v-card class="row-peque" elevation="0" v-for="(f,i) in fechas" :key="i">
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="1"><i :class="'fa fa-'+f.icon"></i></v-col>
								<v-col class="no-mp" cols="5">{{f.texto}} </v-col>
								<v-col class="no-mp" cols="5">{{f.fecha | dateTime}}</v-col>
							</v-row>
						</v-card>
					</v-card>
				</v-col>
				<!-- -->
				<v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="tipo === 'historial'">
					<v-card style="padding:5px; background-color:'#FFFFF';" >
						<v-card elevation="0" style="margin:5px 0 5px 0;"> 
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="12" v-if="permisoAbonar">
									<v-btn  x-small color="primary" outlined v-on:click="crearAbono" >
										Agregar Abono
									</v-btn>
								</v-col>
								<v-col class="no-mp" cols="12" v-else> Abonos </v-col>
							</v-row>
						</v-card>
						<v-card class="row-media abono" elevation="0" v-for="(a,i) in pedido.abonos" :key="i">
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="5">{{a.created_at|dateTime}}</v-col>
								<v-col class="no-mp" cols="4">
									<v-chip x-small v-if="a.cobrador.id !== pedido.repartidor_id" :key="'other'" color="red">{{a.cobrador.nombre}}</v-chip>
									<v-chip x-small v-else color="primary" :key="'own'">{{a.cobrador.nombre}}</v-chip>
								</v-col>
								<v-col class="no-mp" cols="3">$ {{a.total | moneda}}</v-col>
							</v-row>
						</v-card>
					</v-card>
				</v-col>
				<!-- -->
				<!-- -->
				<v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="tipo === 'historial'">
					<v-card style="padding:5px; background-color:'#FFFFF'" >
						<v-card elevation="0" style="margin:5px 0 5px 0;"> 
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="12"> Productos </v-col>
							</v-row>
						</v-card>
						<v-card class="row-media" elevation="0">
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="3">nombre</v-col>
								<v-col class="no-mp" cols="3">cantidad</v-col>
								<v-col class="no-mp" cols="3">precio</v-col>
								<v-col class="no-mp" cols="3">total</v-col>
							</v-row>
						</v-card>
						<v-card class="row-media producto" elevation="0" v-for="(a,i) in pedido.items" :key="i">
							<v-row class="no-mp" >
								<v-col class="no-mp" cols="3">{{a.producto.nombre}}</v-col>
								<v-col class="no-mp" cols="3">
									<v-chip x-small color="primary" >{{a.cantidad}} {{a.producto.unidad_medida}}</v-chip>
								</v-col>
								<v-col class="no-mp" cols="3">$ {{a.precio|moneda}}</v-col>
								<v-col class="no-mp" cols="3">$ {{totalProdcto(a) |moneda}}</v-col>
							</v-row>
						</v-card>
					</v-card>
				</v-col>
				<!-- -->
			</v-row>
		</v-card-text>
		
		<!-- crear abono-->
		<v-dialog v-model="modalAbono" width="600" >
			<v-form ref="form">
				<v-card v-if="cobradores.length > 0" >
					<v-row class="no-mp">
						<v-col cols="2" ><v-chip dark color="red">{{pedido.id}}</v-chip></v-col>
						<v-col >{{cliente}}</v-col>
						<v-col >Restante $ {{restante | moneda}} </v-col>
					</v-row>
					<v-row class="no-mp">
						<v-col cols="4">
							<v-text-field v-model="monto_abono" solo label="Cantidad Abono" :rules="reglas.monto_abono" prefix="$"></v-text-field>
						</v-col>
						<v-col cols="8">
							<v-select solo :items="cobradores" :item-text="'nombre'" return-object v-model="cobrador" label="Cobrador" ></v-select> 
						</v-col>
						<v-col cols="8">
							<v-btn color="primary" v-on:click="guardarAbono">Guardar Abono</v-btn> 
							<v-btn color="red" v-on:click="modalAbono = false">Cancelar</v-btn> 
						</v-col>
					</v-row>
				</v-card>
			</v-form>
		</v-dialog>
		<!-- ./crear abono-->
  </v-card>
</template>

<script>
import Schema from '../../core/Schema';
import Helper from '../../core/Helper.js';
export default {
  components: {},
  data: () => ({
		monto_abono:null,
		cobrador:null,
		cobradores:[],
		modalAbono:false,
		reglas: {
			monto_abono: [
				(val) => !!val || "Ingresa una cantidad" ,
				(val) => (Number(val) > 0) || "Cantidad invalida" ,
			],
		},
	}),
	props: {
		pedido: {
			type: Object,
			default() { return null;}
		},
		puede_abonar: {
			type: Boolean,
			default() { return false;}
		},
		tipo: { // info, historial
			type: String,
			default() { return 'info';}
		},
	},
	mounted() {
		Schema.models.User.listar(500,1,job => {this.cobradores = job.newData;});
	},
	methods: {
		info() {
			this.verInfo = !this.verInfo;
		},
		crearAbono() {
			this.modalAbono = true;
			this.monto_abono = null;
			this.cobrador = this.pedido.repartidor;
		},
		guardarAbono() {
			if(!this.$refs.form.validate()) {
				return;
			}
			if(this.monto_abono > this.restante) {
				return;
			}
			let abono = {
				total: this.monto_abono,
				created_at: Helper.fechaHora(),
				cobrador_id : this.cobrador.id,
				pedido_id : null,
			};
			this.pedido.pagos = Number(this.pedido.pagos || 0);
			this.pedido.pagos += Number(abono.total);
			if(this.pedido.pagos === this.pedido.total) {
				this.pedido.estado = 'finalizado';
				this.pedido.fecha_finalizado = Helper.fechaHora();
			}
			Schema.models.Pedido.abonar(this.pedido,abono, job => {
				job.newData.abono['cobrador'] = this.cobrador;
				this.pedido.abonos.push(job.newData.abono);
				this.modalAbono = false;
			});
		},
		totalProdcto(p) {
			return Number(p.precio) * Number(p.cantidad);
		}
	},
	computed: {
		permisoAbonar() {
			return this.puede_abonar? this.pedido.estado === 'cobro': false;
		},
		cliente() {
			return this.pedido.cliente.es_empresa?this.pedido.cliente.razon_social:this.pedido.cliente.encargado;
		},
		clienteDireccion() {
			return this.pedido.cliente.calles + ' ' 
				+ (this.pedido.cliente.numero_exterior?'#'+this.pedido.cliente.numero_exterior:'S/N') + ' ' 
				+ (this.pedido.cliente.numero_interior?'int. '+this.pedido.cliente.numero_interior:'') + ' ' 
				+ (this.pedido.cliente.asentamiento? this.pedido.cliente.asentamiento.tipo_asentamiento + ' ' 
				+ this.pedido.cliente.asentamiento.asentamiento: '');
		},
		restante() {
			return Number(this.pedido.total) - Number(this.pedido.pagos);
		},
		estaPagado() {
			return this.pedido.total == this.pedido.pagos;
		},
		fechas() {
			let fechas = [];
			if(this.pedido.created_at) {
				fechas.push({ icon:'file', texto:'Fecha de creacion:', fecha: this.pedido.created_at});
			}
			if(this.pedido.fecha_in_produccion) {
				fechas.push({ icon:'upload', texto:'Entrada a produccion: ', fecha: this.pedido.fecha_in_produccion});
			}
			if(this.pedido.fecha_out_produccion) {
				fechas.push({ icon:'download', texto:'Salida de produccion:', fecha: this.pedido.fecha_out_produccion});
			}
			if(this.pedido.fecha_reparto) {
				fechas.push({ icon:'motorcycle', texto:'Fecha reparto:', fecha: this.pedido.fecha_reparto});
			}
			if(this.pedido.fecha_entregado) {
				fechas.push({ icon:'check', texto:'Fecha entrega', fecha: this.pedido.fecha_entregado});
			}
			if(this.pedido.fecha_finalizado) {
				fechas.push({ icon:'exclamation-circle', texto:'Fecha finalizado', fecha: this.pedido.fecha_finalizado});
			}
			return fechas;
		}
	},
};
</script>
<style>
	.row-peque {
		padding:0 5px 0 10px;
		margin:4px 0 2px 0;
		font-size:11px;
	}
	.row-media {
		padding:0 5px 0 10px;
		margin:4px 0 2px 0;
		font-size:13px;
	}
	.abono {
		background-color:#98B8D8 !important;
	}
	.producto {
		background-color:#E09E41 !important;
	}
</style>
