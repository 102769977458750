<template>
	<div>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Compras realizadas</v-card-title>
		</v-toolbar>
		<v-card-text>
			<v-row class="fila">
				<v-col class="columna" cols="2"> Fecha </v-col>
				<v-col class="columna" cols="2" >
					<v-text-field outlined dense type="date" v-model="fecha"  data-date-format="YYYY-mm-dd"> </v-text-field>
				</v-col>
				<v-col class="columna" cols="4"></v-col>
				<v-col class="columna" cols="4">
					<v-btn class="success" v-on:click="reporteCompraConArea"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>
			<v-row class="fila">
				
				<v-col class="columna" cols="2"> Inicio </v-col>
				<v-col class="columna" cols="2" >
					<v-text-field outlined dense type="date" v-model="fecha1"  data-date-format="YYYY-mm-dd"> </v-text-field>
				</v-col>
				<v-col class="columna" cols="2"> Fin </v-col>
				<v-col class="columna" cols="2" >
					<v-text-field outlined dense type="date" v-model="fecha2"  data-date-format="YYYY-mm-dd"> </v-text-field>
				</v-col>
				<v-col class="columna" cols="4">
					<v-btn v-on:click="descargar2" class="success">Descargar</v-btn>
				</v-col>
			</v-row>
			<!-- reporte compras por provedor-->
			<v-row class="fila">
				<v-col class="columna" cols="2">Reporte compras por proveedor </v-col>
				<v-col class="columna" cols="2">
					<v-text-field outlined dense type="date" v-model="fechainicio"  data-date-format="YYYY-mm-dd"> </v-text-field>
				</v-col>
				<v-col class="columna" cols="2">
					<v-text-field outlined dense type="date" v-model="fechafin"  data-date-format="YYYY-mm-dd"> </v-text-field>
				</v-col>
				<v-col class="columna" cols="2">
					
					<v-autocomplete
						v-model="provselec"
						:items="proveedores"
						filled
						chips
						dense
						outlined
						color="blue-grey lighten-2"
						label="Seleccione un provedor"
						item-text="nombre"
          				item-value="id"
						>
						<template v-slot:selection="data">
							<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							close
							color="#154272"
							class="white--text"
							>
							{{ data.item.nombre }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template v-if="(typeof data.item !== 'object')">
							<v-list-item-content v-text="data.item"></v-list-item-content>
							</template>
							<template v-else>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.nombre"></v-list-item-title>
							</v-list-item-content>
							</template>
						</template>
            		</v-autocomplete>
				</v-col>
				<v-col class="columna" cols="4">
					<v-btn class="success" v-on:click="reportesPorProvedor"><i class="fa fa-download"></i> Descargar</v-btn>
				</v-col>
			</v-row>	
			<v-card style="background-color:transparent!important" elevation="0">
				<v-card elevation="0" style="padding:5px 17px 5px 10px;margin:0; background-color:#F8F8F8;" outlined>
					<v-row class="no-mp">
						<v-col cols="3" class="no-mp">Proveedor</v-col>
						<v-col cols="2" class="no-mp">Fecha</v-col>
						<v-col cols="2" class="no-mp">Total</v-col>
						<v-col cols="2" class="no-mp">Pagado</v-col>
						<v-col cols="1" class="no-mp">Folio</v-col>
						<v-col cols="2" class="no-mp">Acciones</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" style="'padding: 1px 5px 1px 5px; margin: 5px 0 0 0; background-color: rgba(16,45,80,0.1); height:55vh; overflow-x: hidden; overflow-y: scroll;'" outlined>
					<div v-for="(d, index) in compras" :key="index">
					<v-card v-if="!d.cancelada"  elevation="0" style="padding:5px;margin:6px 0 0 0;" >
					  
						<v-row style="padding:0px;margin:0;" >
							<v-col cols="3" class="no-mp" style="font-size:14px;">{{d.proveedor.nombre}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">{{d.fecha | date}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">$ {{d.total | moneda}}</v-col>
							<v-col cols="2" class="no-mp" style="font-size:14px;">$ {{d.pagado | moneda}}</v-col>
							<v-col cols="1" class="no-mp" style="font-size:14px;">{{d.folio}}</v-col>
							<v-col cols="2" class="no-mp">
								<v-btn-toggle>
									<v-btn x-small :key="'pre'" depressed color="primary" v-on:click="abonar(d)"><i class="fa fa-dollar-sign"></i></v-btn>
									<v-btn x-small :key="'edi'" depressed dark color="warning" v-on:click="ver(d,index)">
										<i class="fa fa-list"></i>
									</v-btn>
									<v-btn x-small :key="'delete'" depressed dark color="red" v-on:click="eliminar(d,index)">
										<i class="fa fa-trash"></i>
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-card>
				</div>
				</v-card>
			</v-card>
		</v-card-text>
	</v-card>
		<!-- -->
		<!-- Modal Entradas -->
		<v-dialog v-model="entradasModal" width="400" >
			<EntradasDeCompra v-if="entradasModal" :compra="compraSelect" v-on:cerrar="cerrarVer"/>
		</v-dialog>
		<!-- Modal cancelar -->
		<v-dialog v-model="modalCancelar" width="400" >
			<v-card>
        <v-card-title>
          <span class="text-h5">Motivo de la cancelacion</span>
        </v-card-title>
        <v-card-text>
          <v-container>
			<v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12"
                md="12">
                <v-text-field
                  label="Motivo"
				  v-model="motivo"
				  :rules="[v => !!v || 'Agrege un motivo para continuar']"
				  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
		</v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
			dark
		    @click="enviarCancelacion"
          >
            Aceptar
          </v-btn>
          <v-btn
            color="red" dark
            @click="modalCancelar = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
  </div>
</template>

<script>
	import Schema from '../core/Schema';
	import Helper from '../core/Helper';
	import Api from "../utils/Api"
	//import App from '../utils/App';
	//import PagosDeCompra from '../reutilizables/compras/PagosDeCompra';
	import EntradasDeCompra from '../reutilizables/entradas/EntradasDeCompra';
  export default {
    components:{
			//PagosDeCompra,
			EntradasDeCompra,
		},
    props: { },
    data () { return {
			entradasModal: false,
			compras: [],
			fecha:null,
			fecha1:null,
			fecha2:null,
			//provedores 
			fechainicio:null,
			fechafin:null,
			proveedores:[],
			provselec: null,
			token: null, 
			modalCancelar: false,
			compraSelected: null,
			motivo: null 
		};},
		mounted() {
			this.fecha = Helper.fecha();
			this.buscar();
			Schema.models.Proveedor.listar(null, null, (job) => {
			//this.$store.commit('setsplash',{texto:'', activar: false});
				this.proveedores = job.newData;
			});
			
		},
    methods: {
			descargar() {
				window.open('http://reportes.carnescruvi.com.mx/excel/reportecompras/'+this.fecha, '_blank').focus();
			},
			eliminar(compra){
				this.modalCancelar = true
				this.compraSelected = compra;
				// Seleccionamos la compra a realizar 
				
			},
			enviarCancelacion(){
				const valid  = this.$refs.form.validate()
				console.log(valid)
        		if (valid) {
				console.log(this.compraSelected)
				this.compraSelected.cancelada = true;
				this.compraSelected.motivo = this.motivo;
				Schema.models.Compra.canelarCompra(this.compraSelected,this.entradas,job => {
					this.proveedorSelect = null;
					//
					console.log("whats up ?")
					console.log(job)
				});
				this.modalCancelar = false
				this.motivo= null
				}
			},
			reporteCompraConArea(){
				
				if(!this.fecha ){
					alert('Seleccione almenos una fecha Fecha')
					return 
				}
				this.login().then(async (response) => {
				if(!response.error) {
					console.log('log success')
					////*/
					const token = this.token;
					let self = this;
					Api({
						method: 'get',
						url: `/api/compraconarea/${this.fecha}`,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte por fechas.xlsx'
							link.click()
							self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});

			},
			descargar2() {
				window.open('http://reportes.carnescruvi.com.mx/excel/reportecompras2/'+this.fecha1+'/'+this.fecha2, '_blank').focus();
			},
			reportesPorProvedor(){
			const idProv = this.provselec;
			if(!idProv || !this.fechainicio || !this.fechafin){
				alert('Los campos provedor y fecha son nesesarios')
				return 
			}

			this.login().then(async (response) => {
				if(!response.error) {
					console.log('log success')
					////*/
					const token = this.token;
					this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/reporte/provedor/${idProv}/fecha/${this.fechainicio}/fecha2/${this.fechafin}`,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte.xlsx'
							link.click()
							self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});

			
			},
			ver(c) {
				this.compraSelect = c;
				this.entradasModal = true;
			},
			cerrarVer() {
				this.entradasModal = false;
				this.compraSelect = null;
			},
			abonar() {},
			cancelar() {
				//Schema.models.Compra.canelarCompra
				
			},
			buscar() {
				this.$store.commit('setsplash',{texto:'Cargando', activar: true});
				Schema.models.Compra.comprasPorFecha(this.fecha,job => {
					this.compras = job.newData;
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
			},
			async login() {
				await Api.get('/sanctum/csrf-cookie');
				let dataLogin = await Api.post('/api/login',{username:'servicios',password:'servicios'});
				console.log("login",dataLogin.data);
				this.token = dataLogin.data.token;
				return dataLogin.data;
			},
		},
		computed: {},
		watch: { 
			fecha() {
				this.buscar();
			},
		}
  }
</script>
<style>
</style>
