
import Socket from './Socket';
import Helper from './Helper';
import Auth from './Auth';

export default  {
	
	models: {},
	origin: null,
	crud: true,
	ready: false,
	loadModels:null,
	loadAssociations:null,
	BD:null,
	Auth:null,
	
	async initInServer(BD, httpServer, config) {
		this.BD = BD;
		this.origin = 'server';
		this.crud = true;
		await this.BD.init(config);
		this.Auth = new Auth(this.BD.sequelize, this.origin, this.crud);
		this.loadModels();
		await Socket.initServer(httpServer,config.cors);
		this.loadChannelsServer();
		this.loadAssociations();
		this.ready = true;
		return this.ready;
	},
	
	async initInClient(BD,crud,config) {
		this.origin = 'client';
		this.crud = crud;
		if(this.crud) {
			this.BD = BD;
			await this.BD.init(config);
			this.Auth = new Auth(this.BD.sequelize, this.origin, this.crud);
			this.loadModels();
			Socket.initClient(config.server);
			this.loadChannlesClient();
			this.loadAssociations();
		} else {
			this.Auth = new Auth(null, this.origin, this.crud);
			this.loadModels();
			await Socket.initClient(config.server);
			await this.loadChannlesClient();
		}
		this.ready = true;
		return this.ready;
	},
	
	loadChannelsServer() {
		Socket.ioSockets.on('connection', (socket) => {
			console.log("conectado lado server",socket.id);
			this.Auth.setSocket(Socket.ioSockets,socket);
			this.onConnect(Socket.ioSockets,socket);
			socket.on('crud_channel_server',(job) => {
				this.models[job.model].setSocket(Socket.ioSockets,socket);
				this.models[job.model].processAndBroadcast(job).then(() => {});
			});
			socket.on('auth_channel_server',(job) => {
				this.Auth.processAndBroadcast(job).then(() => {});
			});
			socket.on('sicronization_channel',(data) => {
				this.sincronization(data,socket).then(() => {});
			});
			socket.on('disconnect',() => {
				this.Auth.desconectar(socket.id);
				this.onDisconnect(socket);
			});
			socket.on('canal_message_server',(message) => {
				switch(message.broadcast) {
					case 'all' : socket.emit('canal_message_client',message); break;
					case 'private' : socket.to(message.socket_id).emit('canal_message_client',message); break;
				}
			});
		});
	},
	
	loadChannlesClient() { return new Promise((resolve) => {
		Socket.socket.on('crud_channel_client', (job) => { 
			this.models[job.model].tryProcessJobAndHandler(job).then(() => {});
		});
		Socket.socket.on('auth_channel_client', (job) => { 
			this.Auth.setSocket(null,Socket.socket);
			this.Auth.tryProcessJobAndHandler(job).then(() => {});
		});
		Socket.socket.on('connect', () => { 
			console.log("conectado lado cliente",Socket.socket.id);
			this.Auth.setSocket(null,Socket.socket);
			this.onConnect(Socket.socket);
			this.addSocketClient();
			resolve(true);
		});
		Socket.socket.on('sicronization_channel',(data) => {
			this.sincronization(data,Socket.socket).then(() => {});
		});
		Socket.socket.on("disconnect", () => {
			console.log("desconectado...");
			if (this.crud) {
				this.Auth._dissconnect(Socket.socket.id).then(() => {});
			}
			this.onDisconnect(Socket.socket);
		});
	});},
	
	addModel(nombre,Model,config) {
		if(this.crud) {
			this.models[nombre] = new Model(this.BD.sequelize, this.origin, this.crud);
			this.models[nombre].setNodo(config.nodo);
		} else {	
			this.models[nombre] = new Model(null, this.origin, this.crud);
		}
  },
	
	addSocketClient() {
		let m = Object.keys(this.models);
		for(let i = 0; i < m.length; i++) {
			this.models[m[i]].setSocket(null,Socket.socket);
		}
  },
	
	addStoreHook(store,mutation) {
		let m = Object.keys(this.models);
		for(let i = 0; i < m.length; i++) {
			this.models[m[i]].setStore(store,mutation);
		}
  },
	
}
