<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{producto.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-text-field solo label="Nombre" v-model="producto.nombre" :rules="reglas.nombre"></v-text-field>
						<v-text-field solo label="Clave" v-model="producto.clave" disabled></v-text-field>
						<v-text-field solo label="Codigo Barras" v-model="producto.codigo" ></v-text-field>
						<v-select solo label="Categoria" v-model="producto.categoria" :items="categorias"  :rules="reglas.categoria" 
						></v-select>
						<v-select solo label="Unidad Medida" v-model="producto.unidad_medida" :items="unidades"  :rules="reglas.unidad_medida" 
						></v-select>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			producto: {
				type:Object,
				default() { return {
					nombre:null,
					clave:null,
					codigo:null,
					categoria:null,
					unidad_medida:null,
				};}
			}
		},
    data () { return { 
			unidades:['Kg', 'Pza', 'Costal'],
			categorias:['RES', 'CERDO', 'LACTEOS', 'EMBUTIDOS','OTROS','PROMOCIONES','POLLO','PEZ','BIMBO','VERDURAS','ABARROTES'],
			reglas: {
				nombre:[
					(val) => !!val || "Ingresa Nombre" ,
				],
				categoria:[
					(val) => !!val || "Selecciona la categoria" ,
				],
				unidad_medida:[
					(val) => !!val || "Selecciona unidad de medida" ,
				],
			}
		};},
		mounted() {},
    methods: {
			guardar() {
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Producto.countN(this.producto.categoria,totalProd => {
					let proximoId = Number(totalProd.newData) + 1;
					if(!this.producto.id) {
						this.generarClave(proximoId);
					}
					console.log("codigo",this.producto.clave);
					Schema.models.Producto.guardar(this.producto,'all',(job) => {
						this.$store.commit('setsplash',{texto:'', activar: false});
						this.$emit('getproducto',job.newData);
					});
				});
				
			},
			cerrar() {
				this.$emit('getproducto',null);
			},
			generarClave(id) {
				let folio = '' + (!!this.producto.id?this.producto.id:id);
				if(folio.length < 6) {
					let cerosIzquierda = 6 - folio.length;
					for(let i = 0; i < cerosIzquierda; i++) {
						folio = '0' + folio;
					}
				}
				this.producto.clave = this.producto.categoria.substring(0,3) + folio;
			}
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
