<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-card-title> RESETEAR PEDIDO {{pedido.id | folio}}</v-card-title>
			<v-spacer></v-spacer>
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
		</v-toolbar>
		<div >
			<v-row class="no-mp" v-if="noTieneCancelacion">
				<v-col class="no-mp" >
					<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
						<v-form ref="form">
							<v-row>
								<p> Al resetear el pedido pasara a estado de cobro. <br> 
								<strong class="red--text text--lighten-1"> Esta operacion eliminara los abonos realizados </strong>  </p>
							</v-row>
							<v-row>
								<v-textarea
									solo
									v-model="movimiento.descripcion"
									name="input-7-4"
									label="Motivo de reseteo de pedido"
								></v-textarea>
							</v-row>
						</v-form>					
						<v-btn block color="success" v-on:click="guardar">Resetear</v-btn>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="no-mp" v-else>
				<v-col class="no-mp" >
					<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
						<v-form ref="form">
							<h5>{{movimiento.descripcion}}</h5>
						</v-form>					
						<v-btn block color="success" v-on:click="cerrar">Aceptar</v-btn>
					</v-card>
				</v-col>
			</v-row>
		</div>
		
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
import Helper from '../../core/Helper';

export default {
   components:{},
   props: { 
		pedido: {
			type:Object,
			default() { return null;}
		}
	},
   data () { return { 
		movimiento: {
			accion:null,
			tabla:null,
			descripcion:null,
			foreign_key:null,
			user_id:null,
		},
		sucursalSelect: null,
		sucursales:[],
		rules: {},
		unsubscribe: null,
	  };
	},
	created() {
		/* this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'setserverdata' && state.server_data.model == 'Sesion') {
			//	this.revisarSesiones();
			}
		}); */
	},
	beforeDestroy() {
		//this.unsubscribe();
	},
	mounted() {
		//this.revisarSesiones();
	},
	methods: {
		revisarSesiones() {
			this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
			Schema.models.Sucursal.listar(null, null, job => {
				console.log(job);
				this.sucursales = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
		guardar() {
			if(!this.movimiento.descripcion || this.movimiento.descripcion <= 0) {
				alert("Faltan agregar campos");
				return;
			}
			if (!this.pedido.repartidor_id){
				alert("Este pedido no es elegible para resetear debido a que no cuenta con reparto");
				return;
			}
			if (this.pedido.cancelado === 1){
				alert("Este pedido no es elegible para resetear debido a que ya fue cancelado");
				return;
			}  
			if (this.pedido.estado != 'finalizado' && this.pedido.estado != 'cobro'){
				alert("Este pedido no es elegible para resetear");
				return;
			}
			this.movimiento.accion = 'reseteo';
			this.movimiento.tabla ='pedidos';
			this.movimiento.foreign_key = this.pedido.id;
			this.movimiento.user_id = this.$store.state.user.id;
			this.movimiento.created_at = Helper.fechaHora();
			//this.$store.commit('setsplash',{texto:'Guardando', activar: true});
			console.log(this.movimiento)
			Schema.models.Movimiento.crearReseteo(this.movimiento, (job) => {
				//this.pedido.cancelado = 1;
				console.log(job.newData)
			    //this.pedido['cancelacion'] = [job.newData.cancelacion];
			   this.$emit('getReseteo', job.newData.reseteo , job.newData.pedido);
			   this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
		cerrar() {
			this.$emit('getReseteo',null);
		}
	},
	computed: {
		laCancelacion() {
			return this.pedido.cancelacion[0];
		},
		noTieneCancelacion() {
			return true;
		},
		sucursalConectada() {
			let activa = false;
			this.sucursales.forEach((item) => {
				if(this.pedido.sucursal_id == item.id) {
					activa = item.conectada;
					this.sucursalSelect = item;
				}
			});
			return true;
			
		},
	},
	watch: {}
}
</script>
<style>
</style>
