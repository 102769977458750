<template> 
	<v-card>
		<v-toolbar color="grey" dark flat> 
			<v-btn icon v-on:click="finalizar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title> Consulta de ventas por cliente {{ cliente.razon_social }}</v-card-title>
		</v-toolbar>
    <v-card-text>
			<v-form ref="form" lazy-validation>
			<v-container fluid>
				<v-row>
    <v-col
      cols="12"
      sm="4"
    >
      <v-date-picker
        v-model="dates"
        locale="mx-MX"
        range
      ></v-date-picker>
    </v-col>
    <v-col
      cols="12"
      sm="8"
    >
	<v-row>
	<v-text-field
	v-model="dateRangeText"
	label="Seleccione rango de fechas para busqueda "
	prepend-icon="fa-calendar"
	readonly
	></v-text-field>
</v-row>
	<v-row>
		<v-btn v-if="buttonDescargar" :loading="loading2"
      	:disabled="loading2" color="success" v-on:click="descargarReporteCliente()" >
        Descargar 
		<v-icon
        right
        dark
      >
	  fa-file-excel
      </v-icon>
      </v-btn>
	</v-row>
	
    </v-col>
	<v-col>
		<v-btn color="success" 
		:loading="loading"
      	:disabled="loading"  
		v-on:click="buscarPedidos()" >
        Buscar
      </v-btn>
	</v-col>
  </v-row>
  <v-row>
	<v-col>
<template>
  <v-simple-table v-if="buttonDescargar" :outlined="true" :bordered="true" :shaped="true"  dense>
    <template v-slot:default>
      <thead>
        <tr class="blue " >
			<th class="text-left white--text">
           Folio 
          </th>
          <th class="text-left white--text">
           Cliente 
          </th>
		  <th class="text-left white--text">
            Estado
          </th>
		  <th class="text-left white--text">
            Fecha
          </th>
		  <th class="text-left white--text">
            Total 
          </th>
		  <th class="text-left white--text">
            Pagos 
          </th>
		  <th class="text-left white--text">
            Adeudos 
          </th>
		  <th class="text-left white--text">
           Sucursal
          </th>
        </tr>
      </thead>
      <tbody>
		<tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.id | folio }}</td>
          <td>{{ item.cliente.razon_social }}</td>
		  <td>{{ item.estado }}</td>
		  <td>{{ item.created_at }}</td>
		  <td> ${{ item.total | moneda }}</td>
		  <td> ${{ item.pagos | moneda }}</td>
		  <td> ${{ (item.total - item.pagos) | moneda }}</td>
		  <td>{{ item.sucursal.nombre }}</td>
		</tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

	</v-col>
  </v-row>
			</v-container>
			</v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" class="white--text" v-on:click="finalizar()" >
        Cancelar
      </v-btn>
      <v-btn color="primary"  v-on:click="guardarCliente()" >
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Schema from '../../core/Schema';
import Helper from '../../core/Helper'
import BuscadorAsentamientos from '../asentamientos/BuscadorAsentamientos.vue';
import Api from "../../utils/Api"
export default {
	components:{
		BuscadorAsentamientos,
	},
	props: {
		cliente: {
			type: Object,
			default() {return {
				asentamiento: null,
				asentamiento_id: null,
				tipo_cliente_id: 1,
				razon_social: null,
				encargado:null,
				es_empresa:null,
				telefono: null,
				movil: null,
				calles: null,
				andador:null,
				manzana:null,
				seccion:null,
				numero: null,
				numero_interior: null,
				numero_exterior: null,
				notas:null,
				vendedor : null,
			};},
		},
		editarTipo: {
			type: Boolean,
			default() {return true;},
		},
	},
	data: () => ({
		itemsTipoCliente:[],
		asentaAux: null,
		select: null,
		// select	
		items: [ ],
        search: null,
        dates: ['2023-01-01', '2023-01-20'],
		buttonDescargar : false,
		loader: null,
        loading: false,
		loading2: false, 
	}),
	mounted() {
		this.dates[0] = Helper.fecha()
		this.dates[1] = Helper.fecha()
	},
	methods: {
		getAsentamiento(a) {
			this.asentaAux =a?a:null;
		},
		clienteDefault() {
			let fields = Object.keys(this.cliente);
			for(let i = 0; i < fields.length; i++) {
				this.cliente[fields[i]] = null;
			}
		},
		guardarCliente() {
			this.finalizar();
		},
		finalizar() {
			this.$emit('getcliente',this.cliente);
		},
		/*buscarPedidos(){
			'http://localhost/api/pedidos/porcliente/cliente/1/fecha/2022-11-12/fecha2/2022-12-12'
		},*/
		descargarReporteCliente(){
			this.loader = 'loading'
			this.loading2 = true
			this.login().then(async (response) => {
				if(!response.error) {
					////*/
					//const idProv = this.provselec;
					const token = this.token;
					const clienteId = this.cliente.id
					const date1 = this.dates[0]
					const date2 = this.dates[1]
					//this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/pedidos/reporte/cliente/`+clienteId+`/fecha/`+date1+`/fecha2/`+date2,
						responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'Reporte clientes.xlsx'
							link.click()
							self.loader = null
						    self.loading2 = false
							//self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});
		},
		buscarPedidos(){
			this.loader = 'loading'
			this.loading = true
			this.loader = 'loading'
			this.login().then(async (response) => {
				if(!response.error) {
					////*/
					//const idProv = this.provselec;
					const token = this.token;
					const clienteId = this.cliente.id
					const date1 = this.dates[0]
					const date2 = this.dates[1]
					//this.$store.commit('setsplash',{texto:'Cargando', activar: true});
					let self = this;
					Api({
						method: 'get',
						url: `/api/pedidos/porcliente/cliente/`+ clienteId +`/fecha/`+date1+`/fecha2/`+date2,
						//responseType: 'arraybuffer',
						headers: {'Authorization': 'Bearer ' + token },
						}).then(function(response) {
							self.buttonDescargar = true
							self.loading =false
							self.items = response.data.pedidos
							//self.$store.commit('setsplash',{texto:'', activar: false});
					})					
				}
			});
		},
		async login() {
			await Api.get('/sanctum/csrf-cookie');
			let dataLogin = await Api.post('/api/login',{username:'servicios',password:'servicios'});
			//console.log("login",dataLogin.data);
			this.token = dataLogin.data.token;
			return dataLogin.data;
		},
	},
	computed: {
		asentamiento() {
			if(!this.asentaAux) {
				return null;
			}
			return this.asentaAux.tipo_asentamiento + " " + this.asentaAux.asentamiento + " " +
			"C.P. "+this.asentaAux.cp + " " +
			this.asentaAux.municipio+", "+this.asentaAux.estado;
		},
        dateRangeText () {
            return this.dates.join(' ~ ')
        },
		labelNombre() {
			return this.cliente.es_empresa?"Nombre del encargado":"Nombre del cliente(*)";
		}
	},
	watch: {
	search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>