import axios from "axios";

const hostname = location.hostname;

if (hostname.localeCompare('crm.carnescruvi.com.mx')){
  console.log('Produccion')
}

console.log(hostname)
let Api = axios.create({
  
  baseURL: "http://api-servicios.carnescruvi.com.mx",//produccion
  // baseURL: "http://api-servicios.fuentesoftware.com",
  // baseURL: "http://api-servicios.test/",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    },
    post: {
      "Content-Type": "application/json"
    }
  }
});

//Api.defaults.withCredentials = true;
Api.defaults.withCredentials = true;

/**
 * https://github.com/axios/axios#interceptors
 *
 * Cachando las respuestas de Error
 */

Api.interceptors.response.use(
  response => response,
  function(error) {
    if (401 === error.response.status) {
      localStorage.clear();
      //alert('Su sesión ha expirado ' + window.location.hostname + 'port' + window.location.port);
      //location.reload();
    } else if (422 === error.response.status) {
      // alert(JSON.stringify(error.response.data.errors));
      return error.response;
    } else {
      return Promise.reject(error);
    }
  }
);

/*
 * Interceptor para request

Api.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token');
  if (!token || 0 === token.length) {
		delete config.headers.Authorization;
	} else {
		//console.log('existe un token',token);
		config.headers.Authorization = `Bearer ${token}`;
	}
  return config;
});

 */
//window.Api = Api;

export default Api;
