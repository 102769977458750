<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{compra.proveedor.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:0; padding:0; background-color:#F0F0F0;">
					<table class="tabla">
						<tr>
							<td>Insumo</td>
							<td>Cantidad</td>
							<td>Precio</td>
							<td>Total</td>
						</tr>
						<tr v-for="i,index in entradas" :key="index">
							<td>{{i.e_insumo.nombre}}</td>
							<td>{{i.cantidad}}</td>
							<td>{{i.precio | moneda}}</td>
							<td>{{total(i) | moneda}}</td>
						</tr>
					</table>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
export default {
   components:{},
   props: { 
		compra: {
			type:Object,
			default() { return [];}
		},
	},
	data () { return {
		entradas: [],
	};},
	mounted() { 
		Schema.models.Entrada.buscarPor('compra_id', this.compra.id, job => {
			this.entradas = job.newData;
		});
	},
  methods: { 
		cerrar() {
			this.$emit("cerrar");
		},
		total(i) {
			return Number(i.cantidad) * Number (i.precio);
		},
	},
	computed: {},
	watch: { }
}
</script>
<style>
</style>
