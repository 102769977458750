import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class InsumoEnvio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        primaryKey: true,
        type: DataTypes['STRING']
      },
      insumo_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      envio_id: {
				allowNull: false,
        type: DataTypes['STRING']
      },
      nota: {
				allowNull: true,
        type: DataTypes['STRING']
      },
      cantidad: {
				allowNull: false,
        type: DataTypes['FLOAT']
			},
      created_at: {
        allowNull: true,
        type: DataTypes['DATE']
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'insumos_envios',
			modelName: 'InsumoEnvio',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [];
	}
	
	/**
   * @override
  **/
  
  async _saveAll(arrayData, sucursal_id) {
		let newData = [];
		for(let i = 0; i < arrayData.length; i++) {
			newData.push(await this._save(arrayData[i], sucursal_id));
		}
		return newData;
	}
	
	/**
   * @override
  **/
  
  async _save(data, sucursal_id) {
		if(!!data.dataValues) {
			data = data.dataValues;
		}
		let model = null;
		if(!!data[this.primaryKey]) {
			model = await this.model.findByPk(data[this.primaryKey],{attributes: this.fieldsValids});
		} else {
			if(this.origin == 'server') {
				return null;
			}
			let idSig = await Schema.models.Id._getIdSiguiente('insumos_envios',sucursal_id);
			//console.log("id,siguiente insumos", idSig);
			data.id = idSig  + ':' + sucursal_id;
			await Schema.models.Id._amuentarId('insumos_envios', sucursal_id);
		}
		if(model) {
			for(let field in data) {
				if(this.fieldsValids.includes(field)) {
					model[field] = data[field];
				}
			}
			await model.save();
		} else {
			data['created_at']= Helper.fechaHora();
			model = await this.model.create(data);
		}
		if(!!model.dataValues) {
			model = model.dataValues;
		}
		model.created_at= Helper.fechaToString(model.created_at);
		return model;
	}
	
}
