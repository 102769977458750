<template>
	<v-card style="margin:10px; background-color:#E5E5E5;" min-height="85vh">
		<v-container style="margin:0; padding:0; width:100%; max-width:100%;">
			<v-row class="no-mp">
				<v-col cols="8" class="no-mp">
					<v-row class="no-mp">
						<v-col>
							<v-card  class="pa-1" outlined tile >
								<v-simple-table height="400px">
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left"></th>
												<th class="text-left">Nombre</th>
												<th class="text-left">Cantidad</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(i, index) in insumos" :key="index">
												<td><v-chip dark color="red" v-on:click="borrar(index,i)" > <i class="fa fa-times"></i></v-chip></td>
												<td>{{i.s_insumo.nombre}}</td>
												<td>{{i.cantidad}} {{i.s_insumo.presentacion}}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>
						</v-col>
					</v-row>
					<v-row class="no-mp">
						<v-col cols="5" >
							<v-select label="Sucursal" :items="sucursales" :item-text="'nombre'" v-model="sucursalSelect" return-object></v-select>
						</v-col>
						<v-col cols="4" > 
							<v-btn v-on:click="enviarInsumos" :key="'apertura'">Salida de inventario</v-btn> 
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="4"><InsumoBuscar v-if="mostrarBuscador" v-on:getsalida="getSalida" /></v-col>
			</v-row>
		</v-container>
		
	</v-card>
</template>

<script>

import InsumoBuscar from '../reutilizables/insumos/InsumoBuscar.vue';
import EnvioInsumos from '../reutilizables/envios/EnvioInsumos.vue';
import EnviosTinyList from '../reutilizables/envios/EnviosTinyList.vue';
import Schema from '../core/Schema'
//import Ticket from '../utils/Ticket.js';
import Helper from '../core/Helper.js';

export default {
  components: {
		InsumoBuscar,
		EnvioInsumos,
		EnviosTinyList,
	},
	props: {
		cliente: {
			type:Object,
			default() {return null;}
		},
		tipo_sucursal: {
			type:String,
			default() {return 'operatel';}
		},
	},
  data: () => ({
		insumos: [],
		mostrarBuscador:true,
		listaEnvios:true,
		envio:{total:null},
		sucursales:[],
		sucursalSelect:null,
		envioDefault: {
			id:null,
			cajero_id:null,
			sucursal_id:null,
			tipo:null,
		},
	}),
	mounted() { 
		this.$store.commit('setsplash',{texto:'Cargando', activar: true});
		Schema.models.Sucursal.listar(null,null,job => {
			this.sucursales = job.newData;
			this.$store.commit('setsplash',{texto:'', activar: false});
		});
		this.envio = Object.assign({},this.envioDefault);
	},
	methods: {
		enviarInsumos() {
			if(!this.sucursalSelect) {
				alert('Selecciona una sucursal');
				return;
			}
			if(this.insumos.length <= 0) {
				alert('Agrega insumos a enviar');
				return;
			}
			this.mostrarBuscador = false;
			this.$store.commit('setsplash',{texto:'Guardando', activar: true});
			Schema.models.Surtido.crearSalida(this.sucursalSelect.id,this.insumos,job => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				alert("Salida Realizada");
				this.limpiarDatos();
				this.mostrarBuscador = true;
			});
		},
		getSalida(item) {
			if(this.insumos.some((i) => {
				return i.insumo_id == item.insumo_id;
			})) {
				alert("Ya agregaste el insumo " + item.s_insumo.nombre);
				return;
			}
			this.insumos.push(item);
		},
		limpiarDatos() {
			this.insumos = [];
			this.envio = Object.assign({},this.envioDefault);
		},
		borrar(index,i) {
			this.insumos.splice(index,1);
		}
	},
	computed: {
		sucursal() {
			return this.$store.state.sucursal;
		}
	},
	watch: {
		cliente() {
			console.log("cambio de cliente");
			this.limpiarDatos();
		}
	}
};
</script>

<style>
	
</style>
